import FooterHome from "features/Home/components/FooterHome";
import Header from "Layout/Singapore/Header";
import React from "react";
import { Outlet } from "react-router-dom";

export default function DistilleryLayout() {
  return (
    <>
      <Header />
      <Outlet />
      <FooterHome />
    </>
  );
}
