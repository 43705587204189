import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { isSale } from "utils/boolenFunc";
import { formatInt } from "utils/formatNumber";

const initialState = {
  isLoading: false,
  carts: localStorage.getItem("carts")
    ? JSON.parse(localStorage.getItem("carts"))
    : [],
  others: {
    total: 0,
    lengthDram: 0,
    lengthBottle: 0,
    length: 0,
  },
  error: null,
};

export const cartSlice = createSlice({
  name: "cartlocal",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    // has error
    setError: (state, action) => {
      state.error = action.payload;
    },

    // add to cart
    addToCart: (state, action) => {
      const inventory = action.payload.inventory;
      const quantity = action.payload.quantity || 1;
      const isExist = state.carts.find(
        (item) => item._id === action.payload._id
      );
      if (isExist && isExist.quantity >= inventory) {
        state.isLoading = false;
        toast.error("This item is temporarily out of stock", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (isExist) {
        state.carts = state.carts.map((item) => {
          if (item._id === action.payload._id) {
            item.quantity = formatInt(item.quantity) + quantity;
          }
          return item;
        });

        localStorage.setItem("carts", JSON.stringify(state.carts));
        state.isLoading = false;
        state.error = null;
        toast.success("Add To Basket Success", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      } else {
        let data = action.payload;
        state.carts.push({ ...data, quantity: quantity });
        localStorage.setItem("carts", JSON.stringify(state.carts));
        state.isLoading = false;
        state.error = null;
        toast.success("Add To Basket Success", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
    },

    // update quantity
    updateQuantity: (state, action) => {
      state.carts = state.carts.map((item) => {
        if (item._id === action.payload) {
          if (item.inventory <= item.quantity) {
            toast.error("Out of stock", {
              position: toast.POSITION.TOP_CENTER,
            });
            return item;
          }
          item.quantity = formatInt(item.quantity) + 1;
        }
        return item;
      });
      localStorage.setItem("carts", JSON.stringify(state.carts));
      state.isLoading = false;
      state.error = null;
    },

    // update quantity minus
    updateQuantityMinus: (state, action) => {
      state.carts = state.carts.map((item) => {
        if (item._id === action.payload) {
          item.quantity = formatInt(item.quantity) - 1;
        }
        return item;
      });
      state.carts = state.carts.filter((item) => item.quantity > 0);
      localStorage.setItem("carts", JSON.stringify(state.carts));
      state.isLoading = false;
      state.error = null;
    },

    // remove from cart
    removeFromCart: (state, action) => {
      state.carts = state.carts.filter((item) => item._id !== action.payload);
      localStorage.setItem("carts", JSON.stringify(state.carts));
      state.isLoading = false;
      state.error = null;
    },

    getCartOther: (state, action) => {
      let total = 0;
      let lengthDram = 0;
      let lengthBottle = 0;
      state.carts.forEach((item) => {
        total +=
          (isSale(item.prod_price, item.prod_price_sale)
            ? item.prod_price_sale
            : item.prod_price) * formatInt(item.quantity);
        if (["Dram Shop"].includes(item.prod_type)) {
          lengthDram += formatInt(item.quantity);
        } else {
          lengthBottle += formatInt(item.quantity);
        }
      });
      state.others.total = total;
      state.others.lengthDram = lengthDram;
      state.others.lengthBottle = lengthBottle;
      state.others.length = lengthDram + lengthBottle;
      state.isLoading = false;
      state.error = null;
    },

    // clear cart
    clearCart: (state, action) => {
      state.carts = [];
      localStorage.removeItem("carts");
      state.isLoading = false;
      state.error = null;
    },
  },
});

export default cartSlice.reducer;
export const {
  setIsLoading,
  setError,
  addToCart,
  updateQuantity,
  updateQuantityMinus,
  removeFromCart,
  getCartOther,
  clearCart,
} = cartSlice.actions;
