import Header from "Layout/Singapore/Header";
import FooterHome from "features/Home/components/FooterHome";
import React from "react";
import { Outlet } from "react-router-dom";

export default function AuthLayout() {
  return (
    <div>
      <Header />
      <Outlet />
      <FooterHome />
    </div>
  );
}
