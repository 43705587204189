import OrderApi from "api/order.api";
import { getCookie } from "config/cookies";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { AiFillInstagram } from "react-icons/ai";
import { BsFacebook, BsTwitter } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { GetOrdersByUserAsync } from "redux/modules/order";
import "./index.scss";
import { removeFromCart } from "redux/modules/cart";
import { removeProduct } from "redux/modules/order";

function FunctionDown(props) {
  const auction_id = props.auction_id;

  const user = getCookie("user");
  const dispatch = useDispatch();

  const handleRemoveProduct = (product_id, auction_id) => {
    return;
    confirmAlert({
      title: "Confirm to remove product",
      message: "Are you sure to remove this product?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const res = OrderApi.removeProduct(
              user._id,
              product_id,
              auction_id
            );
            res.then((res) => {
              if (res.code === "success") {
                dispatch(GetOrdersByUserAsync(user._id));
              } else {
                toast.error(res.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleRemoveBottle = (product_id) => {
    if (props.type) {
      confirmAlert({
        title: "Confirm to remove product",
        message: "Are you sure to remove this product?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              dispatch(removeFromCart(product_id));
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } else {
      let user_id = "";
      if (user !== "" && user !== undefined) {
        user_id = JSON.parse(user)?._id;
      }
      confirmAlert({
        title: "Confirm to remove product",
        message: "Are you sure to remove this product?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              dispatch(removeProduct(user_id, product_id));
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    }
  };

  // if (true) return;
  if (props.functionDown !== props.product_id) return null;
  return (
    <>
      {auction_id ? (
        <div className={`function_down function_down_show`}>
          <div className="function_down_content">
            {/* <div className="function_down_content_title">
          <span>Function Down</span>
        </div> */}
            <div className="function_down_content_body">
              <ul className="function_down_content_body_list">
                {!props.isTarget && (
                  <>
                    {/* <li>Similar Product</li> */}
                    <li
                      onClick={() => {
                        handleRemoveProduct(props.product_id, props.auction_id);
                      }}
                    >
                      Remove Product
                    </li>
                  </>
                )}
              </ul>
            </div>
            {/* <div className="function_down_content_footer">
              {props.isTarget && (
                <div>
                  <span>
                    The target number is almost hit. Tell your friend now
                  </span>
                  <ul>
                    <li>
                      <Link to="/">
                        <BsFacebook />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <BsTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <AiFillInstagram />
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div> */}
          </div>
        </div>
      ) : (
        <>
          <div className={`function_down function_down_show`}>
            <div className="function_down_content">
              <div className="function_down_content_body">
                <ul className="function_down_content_body_list">
                  {/* <li>Similar Product</li> */}
                  <li
                    onClick={() => {
                      handleRemoveBottle(props.product_id);
                    }}
                  >
                    Remove Product
                  </li>
                </ul>
              </div>
              {/* <div className="function_down_content_footer">
                <div>
                  <span>
                    The target number is almost hit. Tell your friend now
                  </span>
                  <ul>
                    <li>
                      <Link to="/">
                        <BsFacebook />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <BsTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <AiFillInstagram />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default React.memo(FunctionDown);
