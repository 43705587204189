import React, { useMemo } from "react";
import { formatCurrencySGD } from "utils/formatNumber";

export default function ItemShop({ item }) {
  const isSale = useMemo(() => {
    return item.price_sale !== 0 && item.price_sale < item.price;
  }, [item.price_sale, item.price]);
  return (
    <div className="check_out_content__item">
      <div className="check_out_content__item__image">
        <img src={item.image} alt="" />
      </div>
      <div className="check_out_content__item__info">
        <div className="check_out_content__item__info__name">
          {item.product_name}
        </div>
        <div className="check_out_content__item__info__price">
          {formatCurrencySGD(isSale ? item.price_sale : item.price)}
        </div>
        <div className="check_out_content__item__info__quantity">
          Quantity: {item.quantity}
        </div>
      </div>
      <div className="check_out_content__item__total">
        {formatCurrencySGD(
          isSale ? item.price_sale * item.quantity : item.price * item.quantity
        )}
      </div>
    </div>
  );
}
