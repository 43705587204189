import React, { useEffect } from "react";
import IMAGES from "constants/images";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FacebookLogin from "react-facebook-login";
import "./index.scss";
import { useDispatch } from "react-redux";
import {
  loginFacebookAsync,
  loginGoogleAsync,
} from "redux/modules/authenticate";
import { toast } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";

export default function OtherLogin(navig) {
  const { t } = useTranslation();
  // const [history, setHistory] = React.useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // useEffect(() => {
  //   if (location.state && location.state.from) {
  //     setHistory(location.state.from);
  //   }
  // }, [location, navigate]);
  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   if (params.get("from")) {
  //     setHistory(params.get("from"));
  //   }
  // }, [location]);
  const responseFacebook = (response) => {
    dispatch(loginFacebookAsync(response))
      .then((res) => {
        if (res.payload.status === "success") {
          setInterval(() => {
            toast.success(t("auth.login_success"), {
              position: toast.POSITION.TOP_CENTER,
            });
            window.location.href = "/";
          }, 1000);
          // navigate(history ? history : "/");
          // toast.success(t("auth.login_success"), {
          //   position: toast.POSITION.TOP_CENTER,
          // });
        } else {
          toast.error(t("auth.login_failed"), {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(t("auth.login_failed"), {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const responseGoogle = (response) => {
    const decode = jwt_decode(response.credential);
    dispatch(loginGoogleAsync(decode))
      .then((res) => {
        if (res.payload.status === "success") {
          setInterval(() => {
            toast.success(t("auth.login_success"), {
              position: toast.POSITION.TOP_CENTER,
            });
            window.location.href = "/";
          }, 1000);

          // navigate(history ? history : "/");
        } else {
          if (res?.payload?.message === "email_or_user_already_exists") {
            toast.error("Email or user already exists", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res?.payload?.message === "invalid_email_or_password") {
            toast.error("Invalid email or password", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.error(t("auth.login_failed"), {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        }
      })
      .catch((err) => {
        toast.error(t("auth.login_failed"), {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  return (
    <div className="other_login">
      <div className="other_login__title">
        <h3>{t("auth.dont_have_account")}</h3>
      </div>
      <div className="other_login__link">
        {/* {location.pathname === "/auth" ? (
          <div className="orther_login_item">
            <img src={IMAGES.WHISKYFIRSTICON} alt="whiskyfirst" />
            <Link to="/auth/register">{t("auth.create_new_account")}</Link>
          </div>
        ) : (
          <div className="orther_login_item">
            <img src={IMAGES.WHISKYFIRSTICON} alt="whiskyfirst" />
            <Link to="/auth">{t("auth.login")}</Link>
          </div>
        )} */}
        <FacebookLogin
          appId="1105907633123908"
          autoLoad={false}
          fields="name,email,picture"
          xfbml={true}
          cookie={false}
          callback={responseFacebook}
          onFailure={(response) => {}}
          textButton={t("auth.continue_with_facebook")}
          cssClass="orther_login_item facebooklogin"
          icon={<img src={IMAGES.FACEBOOKICON} alt="facebook" />}
        />
        <div className="orther_login_item">
          <img src={IMAGES.GOOGLEICON} alt="whiskyfirst" />
          <span className="googlelogin_title">Continue with Google</span>
          <Link className="googlelogin">
            <GoogleLogin
              onSuccess={(response) => responseGoogle(response)}
              onFailure={(response) => {}}
              size="large"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
