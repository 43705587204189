export function formatInt(number) {
  if (number < 0) {
    return 0;
  }
  return Math.floor(number);
}

export function formatCurrencySGD(number) {
  if (number === null || number === undefined) return "SGD $0.00";
  const formattedNumber = number.toLocaleString("en-SG", {
    style: "currency",
    currency: "SGD",
  });
  if (formattedNumber === "$NaN") return "SGD $0.00";
  return `SGD ${formattedNumber}`;
}

export function fCurrency(currency) {
  if (!currency) return;
  const { price, symbol, code } = currency;
  if (code === "SGD") return;
  if (price === null || price === undefined) return `${code} 0`;
  const formattedNumber = price.toLocaleString("en-SG", {
    style: "currency",
    currency: "SGD",
  });

  if (code === "VND")
    return `\n(${formattedNumber
      .replace("$", "")
      .replace(".00", "")}${symbol})`;

  if (code === "HKD")
    return `\n(${code}${symbol}${formattedNumber.replace("$", "")})`;

  return `\n(${symbol}${formattedNumber.replace("$", "")})`;
}

export function fCurrency2(currency) {
  if (!currency) return;
  const { price, symbol, code } = currency;
  if (code === "SGD") return;
  if (price === null || price === undefined) return `${code} 0`;
  const formattedNumber = price.toLocaleString("en-SG", {
    style: "currency",
    currency: "SGD",
  });

  if (code === "VND")
    return `\n(${formattedNumber
      .replace("$", "")
      .replace(".00", "")}${symbol}) ${code}`;

  if (code === "HKD")
    return `\n(${code}${symbol}${formattedNumber.replace("$", "")}) ${code}`;

  return `\n(${symbol}${formattedNumber.replace("$", "")}) ${code}`;
}

//take a decimal place after the comma
export function roundToDecimal(number, decimal) {
  const factor = Math.pow(10, decimal);
  return Math.round(number * factor) / factor;
}

export function fPercentage(number) {
  if (number === null || number === undefined) return "";
  if (number.toString().includes("%")) {
    return number;
  }
  return `${number}%`;
}

export function fVolume(value) {
  let volume = "";
  try {
    if (value) {
      if (value === "N/A") {
        return volume;
      }

      if (value.endsWith("cl")) {
        volume = value;
      } else if (value.endsWith("0ml")) {
        volume = value?.replace("0ml", "cl");
      } else if (Number(value) > 0) {
        if (Number(value) >= 200) {
          volume = value.replace("0", "") + "cl";
        } else {
          volume = value + "cl";
        }
      }
    }
  } catch (error) {}
  return volume;
}
