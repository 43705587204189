import { PATHS } from "routes/paths";

const navConfig = {
  items: [
    // {
    //   name: "home",
    //   url: PATHS.root,
    // },
    {
      name: "live_auction",
      children: [
        {
          name: "singapore_auction",
          url: PATHS.whiskyAuction.singapore,
          location: "Singapore",
        },
        {
          name: "malaysia_auction",
          url: PATHS.whiskyAuction.malaysia,
          location: "Malaysia",
        },
        {
          name: "vietnam_auction",
          url: PATHS.whiskyAuction.vietnam,
          location: "Vietnam",
        },
      ],
    },
    {
      name: "previous_auction",
      // url: PATHS.previousBids,
      children: [
        {
          name: "singapore_auction",
          url: PATHS.previousAuctions.singapore,
        },
        {
          name: "malaysia_auction",
          url: PATHS.previousAuctions.malaysia,
        },
        {
          name: "vietnam_auction",
          url: PATHS.previousAuctions.vietnam,
        },
      ],
    },
    {
      name: "register_lots",
      children: [
        {
          name: "submit_lots",
          url: PATHS.registerLots.submitLots,
        },
        {
          name: "request_valuation",
          url: PATHS.registerLots.requestValuation,
        },
        {
          name: "auction_schedule",
          url: PATHS.auctionSchedule,
        },
      ],
    },
  ],
  carts: {
    name: "cart",
    url: PATHS.cart.root,
  },
};

export default navConfig;
