import axiosClient from "./axiosClient";

const OrderApi = {
  getOrders: () => {
    return axiosClient.get("/order");
  },
  bidProduct: (product_id, user_id, auction_id, email) => {
    return axiosClient.post("/order/bid_product", {
      product_id,
      user_id,
      auction_id,
      email,
    });
  },
  subProduct: (product_id, user_id, auction_id) => {
    return axiosClient.post("/order/sub_product", {
      product_id,
      user_id,
      auction_id,
    });
  },
  bidBottle: (user_id, product_id, quantity) => {
    return axiosClient.post("/order/bid_bottle", {
      user_id,
      product_id,
      quantity,
    });
  },
  subBottle: (user_id, product_id) => {
    return axiosClient.post("/order/sub_bottle", { user_id, product_id });
  },
  removeProduct: (user_id, product_id, auction_id) => {
    return axiosClient.put("/order/remove_product", {
      user_id,
      product_id,
      auction_id,
    });
  },
  getOrdersByUser: (user_id) => {
    return axiosClient.get(`/order/get_order_by_user/${user_id}`);
  },
  getHistoryByUser: (user_id) => {
    return axiosClient.get(`order/history/${user_id}/v2`);
  },
  getCountAllOrders: () => {
    return axiosClient.get("/order/get_all_count_bid");
  },
  getCountOrdersByAuction: (auction_id) => {
    return axiosClient.get(`/order/get_all_count_bid_by_auction/${auction_id}`);
  },
  getCountAllOrdersByAuction() {
    return axiosClient.get("/order/get_all_count_by_auction");
  },
  getCountOrderByAuctionAndProduct: (auction_id, product_id) => {
    return axiosClient.get(
      `/order/get_all_count_bid_by_auction_product/${product_id}/${auction_id}`
    );
  },
  checkOut: (data) => {
    return axiosClient.post("/order/checkout", data);
  },
  checkoutStatus: (secret, data) => {
    return axiosClient.post(`/order/checkout-status/${secret}`, data);
  },
  createPaymentIntent: (data) => {
    return axiosClient.post("/order/create-payment-intent", data);
  },
  getShippingLocation: (location) => {
    return axiosClient.get("/shipping/location/" + location);
  },
};

export default OrderApi;
