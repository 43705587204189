import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WishlistApi from "api/wishlist.api";

const initialState = {
  isloading: false,
  data: [],
};

export const WishlistAsync = createAsyncThunk(
  "wishlist/list",
  async (payload, thunkAPI) => {
    let data = {};
    await WishlistApi.getById(payload.user)
      .then((res) => {
        data = res.items;
      })
      .catch((err) => {
        data = err.response.data;
      });
    return data;
  }
);

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.data.push(action.payload);
    },
    removeFromCart: (state, action) => {
      state.data.pop();
    },
    changeWishlist: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: {
    [WishlistAsync.pending]: (state, action) => {
      state.isloading = true;
    },
    [WishlistAsync.fulfilled]: (state, action) => {
      state.isloading = false;
      state.data = action.payload;
    },
    [WishlistAsync.rejected]: (state, action) => {
      state.isloading = false;
    },
  },
});

export const { addToCart, removeFromCart, changeWishlist } =
  wishlistSlice.actions;
export const selectWishlist = (state) => state.wishlist.data;
export default wishlistSlice.reducer;
