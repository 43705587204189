import React from "react";
import { PulseLoader } from "react-spinners";
import "./index.scss";

export default function LoadingLazy() {
  return (
    <div className="loading_lazy">
      <div className="loading"></div>
    </div>
  );
}
