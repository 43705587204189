import axiosClient from "api/axiosClient";
import React, { useCallback, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import "./confirm.scss";

function ConfirmValuation() {
  const params = useParams();

  const featchData = useCallback(async () => {
    try {
      const id = params.id;
      const bottleId = params.bottleId;
      await axiosClient.get(`/request-valuation/${id}/${bottleId}/confirm`);
    } catch (error) {
      //
    }
  }, [params.bottleId, params.id]);

  useLayoutEffect(() => {
    featchData();
  }, [featchData]);

  return (
    <div id="membership">
      <h1 className="membership__title">ALCOVAULT VALUATION</h1>
      <p>Thank you for agreeing to this valuation.</p>
    </div>
  );
}

export default ConfirmValuation;
