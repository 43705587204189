export function formatLinkName(name) {
  if (!name) return "";
  name = name.toLowerCase();
  // name = name.replace(/\si|i\s|-i|i-/g, "");
  if (name.includes("i-")) name = name.replace(/i-/g, "");
  if (name.includes("-i")) name = name.replace(/-i/g, "");
  name = name.replace(/[^a-zA-Z0-9 ]/g, "");
  return name.replace(/ /g, "-");
}

export function formatName(name) {
  if (!name) return "";
  name = name.toLowerCase();
  name = name.replace(/[^a-zA-Z0-9 ]/g, "");
  return name.replace(/ /g, " ");
}

export function formatLink(link) {
  if (!link) return "";

  // Replace whitespace + 'i' or 'i' + whitespace or '-i' or 'i-' with '-'
  link = link.toLowerCase().replace(/\si|i\s|-i|i-/g, "-");

  // Replace '/' with '-'
  link = link.replace(/\//g, "-");

  // Remove special characters
  link = link.replace(/[^\w\s-]/g, "");

  return link;
}

export function formatLinkShare(url, image, title, description) {
  if (!url) return "";

  return `?back=${url}-ididi-${image}-ididi-${title}-ididi-${description}`;
}
