import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/axiosClient";

const initialState = {
  isloading: false,
  error: null,
  home: [],
  sidebar: [],
  targetBuy: [],
  bottle: [],
  cask: [],
  dram: [],
  reviewRating: [],
  previousBid: [],
  submitShare: [],
  shipping: [],
  howToBid: [],
  howToSell: [],
  independenBottlers: [],
  termsAndConditions: [],
  fAQ: [],
  newsArticles: [],
  glossarys: [],
  partnerShip: [],
  about: [],
  buyWhiskyCask: [],
};

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.isloading = true;
    },
    hasErrors: (state, action) => {
      state.isloading = false;
      state.error = action.payload;
    },
    getHomeSuccess: (state, action) => {
      state.isloading = false;
      state.home = action.payload;
    },
    getSidebarSuccess: (state, action) => {
      state.isloading = false;
      state.sidebar = action.payload;
    },
    getTargetBuySuccess: (state, action) => {
      state.isloading = false;
      state.targetBuy = action.payload;
    },
    getBottleSuccess: (state, action) => {
      state.isloading = false;
      state.bottle = action.payload;
    },
    getCaskSuccess: (state, action) => {
      state.isloading = false;
      state.cask = action.payload;
    },
    getDramSuccess: (state, action) => {
      state.isloading = false;
      state.dram = action.payload;
    },
    getReviewRatingSuccess: (state, action) => {
      state.isloading = false;
      state.reviewRating = action.payload;
    },
    getPreviousBidSuccess: (state, action) => {
      state.isloading = false;
      state.previousBid = action.payload;
    },
    getSubmitShareSuccess: (state, action) => {
      state.isloading = false;
      state.submitShare = action.payload;
    },
    getShippingSuccess: (state, action) => {
      state.isloading = false;
      state.shipping = action.payload;
    },
    getHowToBidSuccess: (state, action) => {
      state.isloading = false;
      state.howToBid = action.payload;
    },
    getHowToSellSuccess: (state, action) => {
      state.isloading = false;
      state.howToSell = action.payload;
    },
    getIndependenBottlersSuccess: (state, action) => {
      state.isloading = false;
      state.independenBottlers = action.payload;
    },
    getTermsAndConditionsSuccess: (state, action) => {
      state.isloading = false;
      state.termsAndConditions = action.payload;
    },
    getFAQSuccess: (state, action) => {
      state.isloading = false;
      state.fAQ = action.payload;
    },
    getNewsArticlesSuccess: (state, action) => {
      state.isloading = false;
      state.newsArticles = action.payload;
    },
    getGlossarysSuccess: (state, action) => {
      state.isloading = false;
      state.glossarys = action.payload;
    },
    getPartnershipSuccess: (state, action) => {
      state.isloading = false;
      state.partnerShip = action.payload;
    },
    getAboutSuccess: (state, action) => {
      state.isloading = false;
      state.about = action.payload;
    },
    getBuyWhiskyCaskSuccess: (state, action) => {
      state.isloading = false;
      state.buyWhiskyCask = action.payload;
    },
  },
});

export const {
  startLoading,
  hasErrors,
  getHomeSuccess,
  getSidebarSuccess,
  getTargetBuySuccess,
  getBottleSuccess,
  getCaskSuccess,
  getDramSuccess,
  getReviewRatingSuccess,
  getPreviousBidSuccess,
  getSubmitShareSuccess,
  getShippingSuccess,
  getHowToBidSuccess,
  getHowToSellSuccess,
  getIndependenBottlersSuccess,
  getTermsAndConditionsSuccess,
  getFAQSuccess,
  getNewsArticlesSuccess,
  getGlossarysSuccess,
  getPartnershipSuccess,
  getAboutSuccess,
  getBuyWhiskyCaskSuccess,
} = bannerSlice.actions;

export default bannerSlice.reducer;

const fetchBanner = (key, func) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axiosClient.post("/banner/find", { query: { key } });
    dispatch(func(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getHome = () => fetchBanner("home_banner", getHomeSuccess);
export const getSidebar = () => fetchBanner("main_sidebar", getSidebarSuccess);
export const getLiveBids = () =>
  fetchBanner("live_bid_banner", getTargetBuySuccess);
export const getPreviousBids = () =>
  fetchBanner("previous_bid_banner", getPreviousBidSuccess);
export const getSubmitAndShare = () =>
  fetchBanner("submit_share_banner", getSubmitShareSuccess);
export const getDramShop = () =>
  fetchBanner("dram_shop_banner", getDramSuccess);
export const getBottleShop = () =>
  fetchBanner("bottle_shop_banner", getBottleSuccess);
export const getCaskShop = () =>
  fetchBanner("cask_shop_banner", getCaskSuccess);
export const getReviewRating = () =>
  fetchBanner("reviews_ratings_banner", getReviewRatingSuccess);
export const getNewArticle = () =>
  fetchBanner("news_articles_banner", getNewsArticlesSuccess);

export const getGlossary = () =>
  fetchBanner("glossary_banner", getGlossarysSuccess);
export const getIndependentBottles = () =>
  fetchBanner("independent_bottles_banner", getIndependenBottlersSuccess);
export const getIBuyWhiskyCaskBottles = () =>
  fetchBanner("buy_whisky_cask", getBuyWhiskyCaskSuccess);
export const getPartnership = () =>
  fetchBanner("partnership_banner", getPartnershipSuccess);

export const getHowToBid = () => fetchBanner("how_to_bid", getHowToBidSuccess);
export const getHowToSell = () =>
  fetchBanner("how_to_sell_banner", getHowToSellSuccess);
export const getShipping = () =>
  fetchBanner("shipping_banner", getShippingSuccess);
export const getFAQ = () => fetchBanner("faq_banner", getFAQSuccess);
export const getTermsAndCondition = () =>
  fetchBanner("terms_and_condition_banner", getTermsAndConditionsSuccess);
export const getAbout = () => fetchBanner("about_banner", getAboutSuccess);
