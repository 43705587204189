import { createSlice } from "@reduxjs/toolkit";
import NewletterApi from "api/newletter.api";

const initialState = {
  isloading: false,
  error: null,
  newletters: [],
  newletter: {},
  comments: [],
};

export const newletterSlice = createSlice({
  name: "newletter",
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.isloading = true;
    },
    hasErrors: (state, action) => {
      state.isloading = false;
      state.error = action.payload;
    },
    getNewlettersSuccess: (state, action) => {
      state.isloading = false;
      state.newletters = action.payload;
    },
    getNewletterSuccess: (state, action) => {
      state.isloading = false;
      state.newletter = action.payload;
    },
    createNewletterSuccess: (state, action) => {
      state.isloading = false;
      state.newletters.push(action.payload);
    },
    updateNewletterSuccess: (state, action) => {
      state.isloading = false;
      state.newletters = state.newletters.map((newletter) =>
        newletter.id === action.payload.id ? action.payload : newletter
      );
    },
    deleteNewletterSuccess: (state, action) => {
      state.isloading = false;
      state.newletters = state.newletters.filter(
        (newletter) => newletter.id !== action.payload
      );
    },
    getNewletterCommentsSuccess: (state, action) => {
      state.isloading = false;
      state.comments = action.payload;
    },
  },
});

export const {
  startLoading,
  hasErrors,
  getNewlettersSuccess,
  getNewletterSuccess,
  createNewletterSuccess,
  updateNewletterSuccess,
  deleteNewletterSuccess,
  getNewletterCommentsSuccess,
} = newletterSlice.actions;
export default newletterSlice.reducer;

export const getNewletters = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await NewletterApi.getNewlettersAllStatus();
    dispatch(getNewlettersSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getNewletter = (id) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await NewletterApi.getNewLetterDetail(id);
    dispatch(getNewletterSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getNewletterComments = (id) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await NewletterApi.getNewCommentInNewLetter(id);
    dispatch(getNewletterCommentsSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};
