import axiosClient from "./axiosClient";

const AuthAPI = {
  login: (email, password) => {
    return axiosClient.post("/auth/login", { email, password });
  },
  register: (data) => {
    return axiosClient.post("/auth/register", data);
  },
  logout: () => {
    return axiosClient.post("/auth/logout");
  },
  update: (data, id) => {
    return axiosClient.put(`/auth/update/${id}`, data);
  },
  getMe: (token) => {
    axiosClient.defaults.headers.common["Authorization"] = `Token ${token}`;
    return axiosClient.get("/auth/me");
  },
  getAll: () => {
    return axiosClient.get("/auth/all");
  },
  loginFacebook: (data) => {
    return axiosClient.post("/auth/facebook", {
      accessToken: data.accessToken,
      id: data.id,
      displayName: data.name,
      email: data.email,
      photos: data.picture.data.url,
    });
  },
  loginGoogle: (data) => {
    return axiosClient.post("/auth/google", data);
  },
  forgotPassword: (email) => {
    return axiosClient.post("/auth/forgot-password", { email });
  },
  resetPassword: (id, token, password) => {
    return axiosClient.post("/auth/reset-password", { id, token, password });
  },
  resetPasswordv2: (id, oldPassword, newPassword) => {
    return axiosClient.post("/auth/resetpassword", {
      id,
      password: oldPassword,
      newPassword,
    });
  },
  verifyEmail: (token) => {
    return axiosClient.get(`/auth/verify/${token}`);
  },
  verifyEmailV2: (token, data) => {
    return axiosClient.post(`/auth/verify/${token}`, data);
  },
  resendEmail: () => {
    return axiosClient.post("/auth/resend-verify-email");
  },
};

export default AuthAPI;
