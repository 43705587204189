import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { loginAsync } from "redux/modules/authenticate";
import ReCAPTCHAS from "components/ReCAPTCHAS";
import OtherLogin from "components/Auth/OtherLogin";

export default function DialogLogin({ setVisible, title, visiableArticle }) {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isCaptcha, setIsCaptcha] = React.useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleLogin = (e) => {
    e.preventDefault();
    const validate = validateLogin({ username, password });

    if (!isCaptcha) {
      toast.info("Please check your reCAPTCHA settings.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (validate.message) {
      toast.error(validate.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    toast.info(t("auth.logging_in"), {
      position: toast.POSITION.TOP_CENTER,
    });
    dispatch(loginAsync({ email: username, password }))
      .then((res) => {
        if (res.payload.status === "success") {
          toast.success(t("auth.login_success"), {
            position: toast.POSITION.TOP_CENTER,
          });
          setVisible(false);
          if (visiableArticle === true) {
            window.location.reload();
          }
        } else {
          toast.error(t("auth.invalid_email_or_password"), {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(t("auth.error_occurred"), {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  return (
    <div
      className="login"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleLogin(e);
        }
      }}
    >
      <h3 className="login_title">{title}</h3>
      {/* <h1 className="login_welcome">
        {history
          ? "You must be logged in to bid or purchase"
          : t("auth.welcome_whisky")}
      </h1> */}
      <form className="login_form" onSubmit={(e) => handleLogin(e)}>
        <div className="box">
          <div className="box__input">
            <label htmlFor="username">{t("auth.username_email")}</label>
            <input
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
            />
          </div>
          <div className="box__input">
            <label htmlFor="password">{t("auth.password")}</label>
            <input
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </div>
        </div>
        <div className="box captcha">
          <ReCAPTCHAS setIsCaptcha={setIsCaptcha} />
        </div>
        <div className="login_button">
          <button disabled={!isCaptcha} type="submit">
            {t("auth.login")}
          </button>
        </div>
      </form>
      <div className="login__forgot">
        <Link to="/auth/forgot-password">{t("auth.forgot_password")}</Link>
      </div>
      <OtherLogin />
    </div>
  );
}

const validateLogin = (values) => {
  if (values.username === "") {
    return {
      message: "Username is required",
    };
  }

  if (values.password === "") {
    return {
      message: "Password is required",
    };
  }

  return {};
};
