import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./modules/authenticate";
import orderReducer from "./modules/order";
import productReducer from "./modules/product";
import auctionReducer from "./modules/auction";
import walletReducer from "./modules/wallet";
import ratingReducer from "./modules/rating";
import wishlistReducer from "./modules/wishlist";
import cartReducer from "./modules/cart";
import countryReducer from "./modules/country";
import checkoutReducer from "./modules/checkout";
import shopReducer from "./modules/shop";
import attributeReducer from "./modules/attribute";
import settingcontentReducer from "./modules/setting/settingcontent";
import newletterReducer from "./modules/newletter";
import bannerReducer from "./modules/setting/banner";
import placeBidReducer from "./modules/place_bid";
import filterSortReducer from "./modules/filter_sort";
import independentReducer from "./modules/independent";
// import orderV2Reducer from "./modules/order.v2";

const store = configureStore({
  reducer: {
    auth: authReducer,
    order: orderReducer,
    product: productReducer,
    auction: auctionReducer,
    wallet: walletReducer,
    rating: ratingReducer,
    wishlist: wishlistReducer,
    cartlocal: cartReducer,
    country: countryReducer,
    checkout: checkoutReducer,
    shop: shopReducer,
    attribute: attributeReducer,
    settingcontent: settingcontentReducer,
    newletter: newletterReducer,
    banner: bannerReducer,
    placeBid: placeBidReducer,
    filter_sort: filterSortReducer,
    independent: independentReducer,
    // orderv2: orderV2Reducer,
  },
});

export default store;
