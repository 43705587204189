import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAPI from "api/auth.api";
import axiosClient from "api/axiosClient";
import { getCookie } from "config/cookies";
import { deleteCookie } from "config/cookies";
import { setCookie } from "config/cookies";

const initialState = {
  isloading: false,
  data: {},
  user:
    getCookie("user") && getCookie("user") !== "undefined"
      ? JSON.parse(getCookie("user"))
      : {},
  accessToken: "",
};

export const loginAsync = createAsyncThunk(
  "auth/login",
  async (payload, thunkAPI) => {
    const { email, password } = payload;
    let data = {};
    await AuthAPI.login(email, password)
      .then((res) => {
        data = res;
      })
      .catch((err) => {
        data = err.response.data;
      });
    return data;
  }
);

export const loginFacebookAsync = createAsyncThunk(
  "auth/loginFacebook",
  async (payload, thunkAPI) => {
    let data = {};
    await AuthAPI.loginFacebook(payload)
      .then((res) => {
        data = res;
      })
      .catch((err) => {
        data = err.response.data;
      });

    return data;
  }
);

export const loginGoogleAsync = createAsyncThunk(
  "auth/loginGoogle",
  async (payload, thunkAPI) => {
    let data = {};
    await AuthAPI.loginGoogle(payload)
      .then((res) => {
        data = res;
      })
      .catch((err) => {
        data = err.response.data;
      });

    return data;
  }
);

export const registerAsync = createAsyncThunk(
  "auth/register",
  async (payload, thunkAPI) => {
    let data = {};
    await AuthAPI.register(payload)
      .then((res) => {
        data = res;
      })
      .catch((err) => {
        data = err.response.data;
      });
    return data;
  }
);

export const updateAsync = createAsyncThunk(
  "auth/update",
  async (payload, thunkAPI) => {
    let data = {};
    await AuthAPI.update(payload.data, payload.id)
      .then((res) => {
        data = res;
      })
      .catch((err) => {
        data = err.response.data;
      });
    return data;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isloading = true;
    },
    register: (state, action) => {
      state.isloading = true;
    },
    verifyEmailSuccess: (state, action) => {
      state.isloading = false;
      state.data = action.payload;
      state.user = action.payload.user;
      setCookie("user", JSON.stringify(action.payload.user), 60 * 60 * 24 * 7);
      axiosClient.defaults.headers.Authorization = `Token ${action.payload.user.token}`;
    },
    profileSuccess: (state, action) => {
      state.isloading = false;
      state.data = action.payload;
      state.user = action.payload.user;
      axiosClient.defaults.headers.Authorization = `Token ${action.payload.user.token}`;
      setCookie("user", JSON.stringify(action.payload.user), 60 * 60 * 24 * 7);
    },
    clearUser: (state, action) => {
      state.user = {};
      state.data = {};
      deleteCookie("user");
    },
  },
  extraReducers: {
    [loginAsync.pending]: (state, action) => {
      state.isloading = true;
    },
    [loginAsync.fulfilled]: (state, action) => {
      state.isloading = false;
      state.data = action.payload;
      state.user = action.payload.user;
      setCookie("user", JSON.stringify(action.payload.user), 60 * 60 * 24 * 7);
    },
    [loginAsync.rejected]: (state, action) => {
      state.isloading = false;
    },
    [registerAsync.pending]: (state, action) => {
      state.isloading = true;
    },
    [registerAsync.fulfilled]: (state, action) => {
      state.isloading = false;
      state.data = action.payload;
      state.user = action.payload.user;
      setCookie("user", JSON.stringify(action.payload.user), 60 * 60 * 24 * 7);
    },
    [registerAsync.rejected]: (state, action) => {
      state.isloading = false;
    },
    [loginFacebookAsync.pending]: (state, action) => {
      state.isloading = true;
    },
    [loginFacebookAsync.fulfilled]: (state, action) => {
      state.isloading = false;
      state.data = action.payload;
      state.user = action.payload.user;
      setCookie("user", JSON.stringify(action.payload.user), 60 * 60 * 24 * 7);
    },
    [loginFacebookAsync.rejected]: (state, action) => {
      state.isloading = false;
    },
    [loginGoogleAsync.pending]: (state, action) => {
      state.isloading = true;
    },
    [loginGoogleAsync.fulfilled]: (state, action) => {
      state.isloading = false;
      state.data = action.payload;
      state.user = action.payload.user;
      setCookie("user", JSON.stringify(action.payload.user), 60 * 60 * 24 * 7);
    },
    [loginGoogleAsync.rejected]: (state, action) => {
      state.isloading = false;
    },
    [updateAsync.pending]: (state, action) => {
      state.isloading = true;
    },
    [updateAsync.fulfilled]: (state, action) => {
      state.isloading = false;
      state.data = action.payload;
      state.user = action.payload.user;
      setCookie("user", JSON.stringify(action.payload.user), 60 * 60 * 24 * 7);
    },
    [updateAsync.rejected]: (state, action) => {
      state.isloading = false;
    },
  },
});

export const {
  login,
  register,
  loginFacebook,
  loginGoogle,
  verifyEmailSuccess,
  clearUser,
  profileSuccess,
} = authSlice.actions;
export const selectAuth = (state) => state.auth;
export default authSlice.reducer;
