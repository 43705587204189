export const validateEmail = (email) => {
  if (!email) return false;
  const re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gm;
  return re.test(email);
};

export const validatePhone = (phone) => {
  if (!phone) return false;
  // ^[0-9\-\+]{9,15}$
  //
  const re = /^[0-9\-+]{8,15}$/;

  return re.test(phone);
};
