import axiosClient from "./axiosClient";

const AuctionApi = {
  getAuctions: () => {
    return axiosClient.get("/auction");
  },
  getAuction: (id) => {
    return axiosClient.get(`/auction/${id}`);
  },
  deleteAuction: (id) => {
    return axiosClient.delete(`/auction/delete/${id}`);
  },
  addAuction: (data) => {
    return axiosClient.post("/auction/create", data);
  },
  updateAuction: (id, data) => {
    return axiosClient.put(`/auction/update/${id}`, data);
  },
  deleteProductAuction: (id) => {
    return axiosClient.delete(`/auction/deleteproduct/${id}`);
  },
  getAuctionbyProductAndAuction: (product_id, auction_id) => {
    return axiosClient.get(`/auction/product/${auction_id}/${product_id}`);
  },
  getAuctionLiveProductAndAuction: (auction_id, product_id) => {
    return axiosClient.get(`/auction/product/${auction_id}/${product_id}/live`);
  },
  getAuctionStill: (location) => {
    return axiosClient.get(`/auction/still/${location}`);
  },
  getAuctionPrevious: (location) => {
    return axiosClient.get(`/auction/previous/${location}`);
  },
  getTargetByAuction: (product_id, auction_id) => {
    return axiosClient.get(
      `/auction/previous/target/${auction_id}/${product_id}`
    );
  },
};

export default AuctionApi;
