import { PATHS } from "routes/paths";

const navFooterTwoConfig = {
  items: [
    {
      // name: "How to Bid",
      name: "how_to_bid",
      url: PATHS.howToBid,
    },
    {
      // name: "How to Sell",
      name: "how_to_sell",
      url: PATHS.howToSell,
    },
    {
      // name: "Shipping & Delivery",
      name: "shipping_delivery",
      url: PATHS.shipping,
    },
    {
      // name: "FAQ",
      name: "faq",
      url: PATHS.fAQ,
    },
    {
      // name: "Terms & Conditions",
      name: "terms_conditions",
      url: PATHS.termsAndConditions,
    },
    {
      // name: "About Us",
      name: "about_us",
      url: PATHS.about,
    },
  ],
};

export default navFooterTwoConfig;
