import React, { useEffect, useLayoutEffect } from "react";
import classes from "../../Header.module.scss";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import {
  BsBasket,
  BsChevronDown,
  BsChevronUp,
  BsFacebook,
  BsInstagram,
  BsYoutube,
} from "react-icons/bs";
import {
  // FaAngleDown,
  FaUserAlt,
  FaWallet,
} from "react-icons/fa";

import { Link } from "react-router-dom";
import logo from "assets/images/logo.png";
import { useTranslation } from "react-i18next";
import navConfig from "../../../NavConfig";
import navConfigMall from "Layout/Singapore/NavConfigMall";
import { useSelector } from "react-redux";
import i18next from "i18next";

const HeaderMobilePhone = ({
  others,
  cartsOther,
  handleLogout,
  viewPort,
  lives,
}) => {
  const [active, setActive] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [lang] = React.useState(localStorage.getItem("lang") || "US");

  const { t } = useTranslation();
  const [menuOpenProfile, setMenuOpenProfile] = React.useState(false);

  useLayoutEffect(() => {
    i18next.changeLanguage("US");
    localStorage.setItem("lang", "US");
  }, [lang]);

  // const menuToggleHandler = () => {
  //   setMenuOpen(!menuOpen);
  // };
  const { user } = useSelector((state) => state.auth);

  const handleLogoClick = () => {
    const anchor = document.createElement("a");
    anchor.href = `/`;
    anchor.click();
  };

  const isMobile = viewPort.width < 768;
  return (
    <>
      <header className={`${classes.header} header`} id="header_mobile">
        <div className={classes.header__content}>
          <div className={classes.header__content__toggle}>
            {!menuOpen ? (
              <AiOutlineMenu
                onClick={() => setMenuOpen(true)}
                className={classes.header__content__toggle__menu}
              />
            ) : (
              <AiOutlineClose
                onClick={() => setMenuOpen(false)}
                className={classes.header__content__toggle__close}
                style={{ color: "white" }}
              />
            )}
          </div>
          <Link
            // to={PATHS.root}
            className={classes.header__content__logo}
            onClick={handleLogoClick}
          >
            <img src={logo} alt="logo" />
          </Link>
          <div className="header_middle_content_mobile">
            <div
              style={{ display: isMobile ? "flex" : "" }}
              className="header_middle_content_top"
            >
              <div className="header-top-sign">
                {user?.username ? (
                  <div
                    className="sign_inup"
                    onClick={() => {
                      setMenuOpenProfile(!menuOpenProfile);
                    }}
                  >
                    <FaUserAlt color="#282930" />

                    {menuOpenProfile && (
                      <ul className="list_user_func">
                        <li>
                          <Link to="/my-account">{t("header.profile")}</Link>
                        </li>
                        <li>
                          <Link onClick={() => handleLogout()}>
                            {t("auth.logout")}
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                ) : (
                  <div className="sign_inup">
                    <Link to="/auth">
                      <FaUserAlt color="#282930" />
                    </Link>
                  </div>
                )}

                {/* <ReactFlagsSelect
                  countries={["US", "CN"]}
                  customLabels={{ US: "EN", CN: "CN" }}
                  selected={lang}
                  onSelect={(code) => setLang(code)}
                  className="selectLanguale-mobile"
                  showSelectedLabel={false}
                  showOptionLabel={false}
                /> */}

                <div className="my_cart">
                  <Link to={navConfig.carts.url}>
                    <BsBasket color="#282930" />
                    <span className="my_cart-title">{t("header.my_cart")}</span>
                    <span className="cart_count">
                      {others.lengthDram +
                        others.lengthBottle +
                        cartsOther.length}
                    </span>
                  </Link>
                </div>
                {user?.username && (
                  <div className="wallet">
                    <Link to="/">
                      <FaWallet color="#282930"></FaWallet>
                    </Link>
                  </div>
                )}
                <div className="social_icon">
                  <span>
                    <a
                      href="https://www.facebook.com/AlcoVaultwhisky"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Facebook"
                    >
                      <BsFacebook />
                    </a>
                  </span>
                  <span>
                    <a
                      href="https://alcovault.com"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Instagram"
                    >
                      <BsInstagram />
                    </a>
                  </span>
                  <span>
                    <a
                      href="http://alcovault.com"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Youtube"
                    >
                      <BsYoutube />
                    </a>
                  </span>
                </div>

                {/* {user.username ? (
                  <>
                    <ReactFlagsSelect
                      countries={["US", "VN", "CN"]}
                      customLabels={{ US: "EN", VN: "VN", CN: "CN" }}
                      selected={lang}
                      onSelect={(code) => setLang(code)}
                      className="selectLangualeLogin"
                    />
                  </>
                ) : (
                  <>
                    <ReactFlagsSelect
                      countries={["US", "VN", "CN"]}
                      customLabels={{ US: "EN", VN: "VN", CN: "CN" }}
                      selected={lang}
                      onSelect={(code) => setLang(code)}
                      className="selectLanguale"
                    />
                  </>
                )} */}
              </div>
            </div>
            <div className="header_middle_content_bottom">
              <nav
                style={{
                  display: menuOpen ? "flex" : "",
                  top: user?.role
                    ? user?.role === "member"
                      ? "40px"
                      : "85px"
                    : "40px",
                }}
                className={`${classes.header__content__nav} mobile_nav`}
              >
                <ul>
                  {navConfigMall.items.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item.children ? (
                          <>
                            <li
                              className="dropdown"
                              onClick={() => {
                                setActive(index);
                              }}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <Link to="#">{t(`header.${item.name}`)}</Link>
                              <div
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                }}
                              >
                                {active === index ? (
                                  <BsChevronDown />
                                ) : (
                                  <BsChevronUp />
                                )}
                              </div>
                            </li>
                            {active === index && (
                              <>
                                {item.children.map((itemchildren, index) => {
                                  const isLive = lives.includes(
                                    itemchildren.location
                                  );
                                  return (
                                    <li
                                      key={index}
                                      onClick={() => setMenuOpen(false)}
                                      className={`header-submenu ${
                                        isLive && "live"
                                      } ${
                                        window.location.pathname.includes(
                                          itemchildren.url
                                        ) && "active"
                                      }`}
                                    >
                                      <Link
                                        to={itemchildren.url}
                                        style={{
                                          fontSize: "11px",
                                          fontFamily: "LatoBold",
                                        }}
                                      >
                                        {t(`header.${itemchildren.name}`)}
                                        {isLive && (
                                          <p className="live-text mobile">
                                            <strong
                                              style={{
                                                fontSize: "10px",
                                              }}
                                            >
                                              Auction is live
                                            </strong>
                                          </p>
                                        )}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <li onClick={() => setMenuOpen(false)}>
                              <Link onClick={() => {}} to={item.url}>
                                {t(`header.${item.name}`)}
                              </Link>
                            </li>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* <div
        className="search_header_mobile"
        id="search_header_mobile"
        // style={{ display: visiable ? "none" : "inline-block" }}
      >
        <input
          type="text"
          placeholder={t("header.search_product")}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          autocomplete="off"
        />
      </div>
      {search !== "" && <ProductSearch search={search} />} */}
    </>
  );
};
export default React.memo(HeaderMobilePhone);
