import logo from "assets/images/logo.png";
import facebook from "assets/images/facebook.svg";
import google from "assets/images/google.svg";
import linkedin from "assets/images/linkedin.svg";
import facebookicon from "assets/images/facebook-icon.png";
import googleicon from "assets/images/google-icon.png";
import whiskyfirsticon from "assets/images/whiskyfirst-icon.png";
import instagramicon from "assets/images/instagram-icon.png";
import twittericon from "assets/images/twitter-icon.png";

const IMAGES = {
  LOGO: logo,
  FACEBOOK: facebook,
  GOOGLE: google,
  LINKEDIN: linkedin,
  FACEBOOKICON: facebookicon,
  GOOGLEICON: googleicon,
  WHISKYFIRSTICON: whiskyfirsticon,
  INSTAGRAMICON: instagramicon,
  TWITTERICON: twittericon,
  NOIMAGE: `https://cdn.shopify.com/s/files/1/0095/1143/6345/t/11/assets/placeholder_600x.png?v=113555733946226816651676958973`,
};

export const NO_IMAGE = `https://i1.wp.com/felastec.ch/wp-content/uploads/2019/04/No-Image.png?ssl=1`;

export default IMAGES;
