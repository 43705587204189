import React from "react";
import { Outlet } from "react-router-dom";

export default function Layout() {
  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     navigate(PATHS.root);
  //   }
  // }, [location, navigate]);
  return (
    <div>
      <Outlet />
    </div>
  );
}
