/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import "./index.scss";
import { BsFacebook, BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { toast } from "react-toastify";
import { PATHS } from "routes/paths";
import SubscriberApi from "api/subscriber.api";
// import { useSelector } from "react-redux";
import GeoIpApi from "api/geoip.api";
import { getCookie } from "config/cookies";
import LoginDialog from "components/LoginDialog";
import { PATHS_DISTILLERY } from "routes/paths";
import navFooterOneConfig from "../NavFooterOneConfig";
import navFooterTwoConfig from "../NavFooterTwoConfig";
import useViewport from "hooks/ViewPort";

export default function FooterHome() {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [visible, setVisible] = React.useState(false);
  const [menuActive, setMenuActive] = React.useState(
    "/whisky-distillery/a-smith-bowman-distillery"
  );
  const location = useLocation();

  React.useEffect(() => {
    setMenuActive("/" + location.pathname.split("/")[1]);
    // kiểm tra xem có sự thay đổi nào trong location không

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  const handleCheckActive = (path) => {
    if (path === menuActive) {
      return "active";
    }
    return "";
  };
  const handleSubmitNewsletter = async (e) => {
    e.preventDefault();
    try {
      if (!email) {
        toast.error("Email is required");
        return;
      }
      if (email.includes("@")) {
        const response = await GeoIpApi.getGeoIp();
        const { geo } = response;

        await SubscriberApi.register({ email, country: geo.country });
        setEmail("");
        toast.success("Register newsletter successfully");
      } else {
        toast.error("Please enter your email!", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      if (error.response.data.message.includes("duplicate key error")) {
        toast.error("Email already exists");
        return;
      }
    }
  };
  const cookie = getCookie("user");
  const handleCheckLogin = () => {
    if (!cookie) {
      setVisible(!visible);
    }
  };
  const viewPort = useViewport();
  const isMobile = viewPort.width <= 600;
  return (
    <>
      {/* <div id="image_noel">
        <img
          src={isMobile ? "/images/noel5.png" : "/images/noel4.png"}
          alt="NOEL"
          loading="lazy"
        />
      </div> */}
      <div className="footer_home_container">
        <div className="footer_home">
          <div className="background">
            <div className="newsletter">
              <h5>{t("footer.newsletter")}</h5>
              {/* <h5>Newsletter</h5> */}
              <div className="form_newsletter">
                <div className="input_email">
                  <input
                    type="text"
                    placeholder={t("footer.enter_email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="btn_submit">
                  <button onClick={handleSubmitNewsletter}>
                    {t("footer.submit")}
                  </button>
                </div>
              </div>
            </div>
            <div className="footer_lnk">
              <ul>
                {navFooterOneConfig.items.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <li className={handleCheckActive(item.url)}>
                        <Link
                          to={item.url}
                          onClick={() => {
                            if (handleCheckActive(item.url) === "active") {
                              window.scrollTo(0, 0);
                              window.location.href = item.url;
                            }
                          }}
                        >
                          {/* {item.name} */}
                          {t(`footer.${item.name}`)}
                        </Link>
                      </li>
                    </React.Fragment>
                  );
                })}
                {/* <li className="distilleries">
                <Link to={PATHS_DISTILLERY.root}>Distilleries</Link>
              </li>
              <li className="glossary">
                <Link to={PATHS.glossary}>Glossary</Link>
              </li>
              <li className="indepudent_bottlers">
                <Link to={PATHS.independenBottlers}>Independent Bottlers</Link>
              </li>

              <li className="partnerships">
                <Link to={PATHS.partnerShips}>Partnerships</Link>
              </li> */}
              </ul>
              <ul>
                {navFooterTwoConfig.items.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <li className={handleCheckActive(item.url)}>
                        <Link
                          to={item.url}
                          onClick={() => {
                            if (handleCheckActive(item.url) === "active") {
                              window.scrollTo(0, 0);
                              window.location.href = item.url;
                            }
                          }}
                        >
                          {/* {item.name} */}
                          {t(`footer.${item.name}`)}
                        </Link>
                      </li>
                    </React.Fragment>
                  );
                })}
                {/* <li className="how-to-buy">
                <Link to={PATHS.howToBid}>How to Bid</Link>
              </li>
              <li className="how-to-sell">
                <Link to={PATHS.howToSell}>{t("footer.how_to_sell")}</Link>
              </li>
              <li className="shipping">
                <Link to={PATHS.shipping}>{t("footer.shipping")}</Link>
              </li>
              <li className="faq">
                <Link to={PATHS.fAQ}>{t("footer.faq")}</Link>
              </li>
              <li className="team-conditions">
                <Link to={PATHS.termsAndConditions}>
                  {t("footer.terms_conditions")}
                </Link>
              </li>
              <li className="about-us">
                {isMobile ? (
                  <Link
                    to={PATHS.about}
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    {t("footer.about_us")}
                  </Link>
                ) : (
                  <Link to={PATHS.about}>{t("footer.about_us")}</Link>
                )}
              </li> */}
              </ul>
            </div>
          </div>
          <div className="footer__container">
            <div className="footer-address-list">
              <div className="footer-address-list__item">
                <h4>{t("footer.info.singapore")}</h4>
                <p>
                  {t("footer.info.address")}: Textile Centre, 200 Jalan Sultan
                  Road, #12-02, Singapore 199018
                </p>
                <p>{t("footer.info.email")}: enquiries@alcovault.com</p>
                <p>{t("footer.info.address")}: +65 89308065</p>
              </div>
              <div className="footer-address-list__item">
                <h4>{t("footer.info.vietnam")}</h4>
                <p>
                  {t("footer.info.address")}: 68A Dong Nai Street, Ward 15,
                  District 10, Ho Chi Minh City 760000, Vietnam
                </p>
                <p>{t("footer.info.email")}: vietnam@alcovault.com</p>
                <p>{t("footer.info.hotline")}: +84 986 312 731</p>
              </div>
              <div className="footer-address-list__item">
                <h4>{t("footer.info.malaysia")}</h4>
                {/* {t("footer.address")}: MALAYSIA, Iskandar Puteri Johor  */}
                {/* <p>
                  {t("footer.info.address")}: Taman Nusantara Prima, Iskandar
                  Puteri Johor, Malaysia
                </p> */}
                <p>
                  {t("footer.info.address")}: NO 20, Jalan SILC 2/15, Kawasan
                  Perindustrian SILC, 79200 Iskandar Puteri, Johor, Malaysia
                </p>
                <p>{t("footer.info.email")}: malaysia@alcovault.com</p>
                <p>{t("footer.info.hotline")}: +6017 7220331</p>
              </div>
            </div>
            <div className="footer__social">
              <div className="footer__social__item">
                <a
                  href="https://www.facebook.com/AlcoVaultwhisky"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Facebook"
                >
                  <BsFacebook />
                </a>
              </div>
              <div className="footer__social__item">
                <a
                  href=" https://www.instagram.com/alcovault_whisky/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Instagram"
                >
                  <BsInstagram />
                </a>
              </div>
              {/* <div className="footer__social__item">
              <a href="#" target="_blank">
                <BsTwitter />
              </a>
            </div> */}
              {/* <div className="footer__social__item">
              <a
                href="https://www.youtube.com/@whiskyfirst1st"
                target="_blank"
                rel="noreferrer"
              >
                <BsYoutube />
              </a>
            </div> */}
            </div>
            <div className="copy-right">
              <p>© Copyright by Alcovault. All Right Reserved</p>
            </div>
          </div>
        </div>
        <LoginDialog
          visible={visible}
          setVisible={setVisible}
          title={"Login to Reward Point"}
        />
      </div>
    </>
  );
}
