import axiosClient from "./axiosClient";

const WalletApi = {
  getWalletByID: (id) => {
    return axiosClient.get(`/wallet/${id}`);
  },
  updateWallet: (id, data) => {
    return axiosClient.put(`/wallet/point/${id}`, data);
  },
};

export default WalletApi;