import React, { useEffect, useState } from "react";
import "./index.scss";
import { loadStripe } from "@stripe/stripe-js";
import config from "config/config";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import CheckoutApi from "api/checkout.api";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "config/cookies";
import DeliveryForm from "./DeliveryForm";
import { FaStripeS } from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";
import { Dialog } from "primereact/dialog";
import { formatCurrencySGD } from "utils/formatNumber";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "routes/paths";
import { formatDateTimeShort } from "utils/formatDate";
import { toast } from "react-toastify";
import CheckoutSuccess from "./CheckoutSuccess";
import ItemShop from "./ItemShop";
import axiosClient from "api/axiosClient";
import { clearCart } from "redux/modules/cart";
import { GetOrdersByUser } from "redux/modules/order";
import paynowLogo from "assets/images/paynow_logo.png";
import Page from "components/Page";
import { getCartOther } from "redux/modules/cart";
import { validateEmail } from "utils/validator";
import { validatePhone } from "utils/validator";
import useViewport from "hooks/ViewPort";
import { setDataSuccess } from "redux/modules/checkout";
import DialogCardSuccess from "./DialogCardSuccess";

const stripePromise = loadStripe(config.stripe.public);
const stripeStatus = config.stripe.status === "disabled" ? false : true;

export default function Checkout() {
  const [clientSecret, setClientSecret] = useState("");
  const [free, setFree] = useState(null);
  const [amount, setAmount] = useState(0);
  const [fee, setFee] = useState(0);
  const { data } = useSelector((state) => state.checkout);
  const [success, setSuccess] = useState(false);
  const [success2, setSuccess2] = useState(false);
  const [checkNext, setCheckNext] = useState(false);
  const [delivery, setDelivery] = useState({
    country: data?.country || "Singapore",
  });
  const { search } = useLocation();
  const [upgrade, setUpgrade] = useState(false);

  console.log(data);

  const dispatch = useDispatch();

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
  }, []);

  const [user, setUser] = useState(
    getCookie("user") ? JSON.parse(getCookie("user")) : null
  );

  useEffect(() => {
    if (upgrade) {
      let checkoutData = {
        delivery,
        memberFee: 5,
        total: 5,
      };

      dispatch(setDataSuccess(checkoutData));
    }
  }, [delivery, dispatch, upgrade]);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const isUpgrade = params.get("upgrade") === "true";
    setUpgrade(isUpgrade);
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (clientSecret) {
      setClientSecret(clientSecret);
    } else {
      if (!data && !isUpgrade) {
        window.location.href = PATHS.cart.root;
      } else {
        if (isUpgrade && !user) {
          navigate(PATHS.root + "/auth?from=/checkout?upgrade=true");
        } else {
          if (isUpgrade && user) {
            if (user?.role === "member") {
              navigate(PATHS.root);
            }
          }
        }
      }
    }
  }, [data, navigate, search, user]);

  useEffect(() => {
    if (user) {
      setDelivery({
        ...delivery,
        firstname: user?.first_name,
        lastname: user?.last_name,
        phone: user?.phone_mb,
        email: user?.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const appearance = {
    theme: "flat",
  };

  const options = {
    clientSecret,
    appearance,
  };

  const onPaymentStripe = () => {
    if (!stripeStatus) {
      if (!upgrade) {
        toast.error("Pay by credit card is disabled", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
    }

    if (!["free-storage", "self"].includes(data?.selectShipping) && !upgrade) {
      if (
        !delivery?.firstname === "" ||
        !delivery?.lastname === "" ||
        !delivery?.phone === "" ||
        !delivery?.email === "" ||
        !delivery?.address === "" ||
        !delivery?.city === "" ||
        !delivery?.country === "" ||
        !delivery?.postal_code === ""
      ) {
        toast.error("Please fill in all fields", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.firstname) {
        toast.error("Please fill in your first name", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.lastname) {
        toast.error("Please fill in your last name", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.phone) {
        toast.error("Please fill in your phone number", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!validatePhone(delivery.phone)) {
        toast.error("Please fill in your phone number correctly", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.email) {
        toast.error("Please fill in your email", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!validateEmail(delivery.email)) {
        toast.error("Please fill in your email correctly", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.address) {
        toast.error("Please fill in your address", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.city) {
        toast.error("Please fill in your city", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.country) {
        toast.error("Please fill in your country", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.postal_code) {
        toast.error("Please fill in your postal code", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
    }

    if (user) {
      // if (upgrade) {
      //   data.country = delivery.country;
      // }
      CheckoutApi.createPaymentIntent({
        ...data,
        delivery,
        user: user?._id,
      })
        .then((res) => {
          if (res.type === "free") {
            setFree(res.clientSecret);
          } else {
            setClientSecret(res.clientSecret);
          }
          setAmount(res.amount);
          setFee(res.credit_card_fee);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            alert(err?.response?.data?.message);
          } else {
            alert("Something went wrong, please try again later");
          }
        });
    } else {
      if (upgrade) {
        toast.error("Please login to upgrade membership", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      CheckoutApi.createPaymentIntent({ ...data, delivery })
        .then((res) => {
          if (res.type === "free") {
            setFree(res.clientSecret);
          } else {
            setClientSecret(res.clientSecret);
          }
          setAmount(res.amount);
          setFee(res.credit_card_fee);
        })
        .catch((err) => {
          //check duplicate email
          if (err?.response?.data?.message.includes("duplicate key error")) {
            alert("Email already exists, please login to continue");
          }
        });
    }
  };

  const onUpgrade = async () => {
    if (user) {
      try {
        const data = {
          user: user?._id,
          delivery,
        };

        const res = await CheckoutApi.upgrade(data);

        if (res?.status === "success") {
          setSuccess(true);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      toast.error("Please login to upgrade membership", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const onPaymentBank = () => {
    if (!["free-storage", "self"].includes(data?.selectShipping)) {
      if (
        !delivery?.firstname === "" ||
        !delivery?.lastname === "" ||
        !delivery?.phone === "" ||
        !delivery?.email === "" ||
        !delivery?.address === "" ||
        !delivery?.city === "" ||
        !delivery?.country === "" ||
        !delivery?.postal_code === ""
      ) {
        toast.error("Please fill in all fields", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.firstname) {
        toast.error("Please fill in your first name", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.lastname) {
        toast.error("Please fill in your last name", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.phone) {
        toast.error("Please fill in your phone number", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!validatePhone(delivery.phone)) {
        toast.error("Please fill in your phone number correctly", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.email) {
        toast.error("Please fill in your email", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!validateEmail(delivery.email)) {
        toast.error("Please fill in your email correctly", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.address) {
        toast.error("Please fill in your address", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.city) {
        toast.error("Please fill in your city", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.country) {
        toast.error("Please fill in your country", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.postal_code) {
        toast.error("Please fill in your postal code", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
    }

    if (user) {
      CheckoutApi.paymentTranfer({ ...data, delivery, user: user?._id })
        .then((res) => {
          setAmount(res.amount);
          setSuccess(true);
          dispatch(GetOrdersByUser(user?._id));
          dispatch(clearCart());
          dispatch(getCartOther());
        })
        .catch((err) => {});
    } else {
      CheckoutApi.paymentTranfer({ ...data, delivery })
        .then((res) => {
          // setClientSecret(res.clientSecret);
          setAmount(res.amount);
          setSuccess(true);
          dispatch(clearCart());
          dispatch(getCartOther());
        })
        .catch((err) => {
          //check duplicate email
          if (err?.response?.data?.message.includes("duplicate key error")) {
            alert("Email already exists, please login to continue");
          } else {
            if (err?.response?.data?.message) {
              alert(err?.response?.data?.message);
            }
          }
        });
    }
  };

  const onPaymentCard = async () => {
    if (!["free-storage", "self"].includes(data?.selectShipping)) {
      if (
        !delivery?.firstname === "" ||
        !delivery?.lastname === "" ||
        !delivery?.phone === "" ||
        !delivery?.email === "" ||
        !delivery?.address === "" ||
        !delivery?.city === "" ||
        !delivery?.country === "" ||
        !delivery?.postal_code === ""
      ) {
        toast.error("Please fill in all fields", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.firstname) {
        toast.error("Please fill in your first name", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.lastname) {
        toast.error("Please fill in your last name", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.phone) {
        toast.error("Please fill in your phone number", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!validatePhone(delivery.phone)) {
        toast.error("Please fill in your phone number correctly", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.email) {
        toast.error("Please fill in your email", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!validateEmail(delivery.email)) {
        toast.error("Please fill in your email correctly", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.address) {
        toast.error("Please fill in your address", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.city) {
        toast.error("Please fill in your city", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.country) {
        toast.error("Please fill in your country", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (!delivery.postal_code) {
        toast.error("Please fill in your postal code", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
    }

    if (user) {
      CheckoutApi.paymentCard({ ...data, delivery, user: user?._id })
        .then((res) => {
          setAmount(res.amount);
          setSuccess2(true);
          dispatch(GetOrdersByUser(user?._id));
          dispatch(clearCart());
          dispatch(getCartOther());
        })
        .catch((err) => {});
    } else {
      CheckoutApi.paymentCard({ ...data, delivery })
        .then((res) => {
          setAmount(res.amount);
          setSuccess2(true);
          dispatch(clearCart());
          dispatch(getCartOther());
        })
        .catch((err) => {
          //check duplicate email
          if (err?.response?.data?.message.includes("duplicate key error")) {
            alert("Email already exists, please login to continue");
          } else {
            if (err?.response?.data?.message) {
              alert(err?.response?.data?.message);
            }
          }
        });
    }
  };

  const onPaymentFree = async () => {
    try {
      await axiosClient.post("/payment/checkout-session-success", {
        clientSecret: free,
      });
      setSuccess(true);
      dispatch(GetOrdersByUser(user?._id));
    } catch (err) {
      toast.error("Something went wrong, please try again later", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const viewPort = useViewport();
  const isMobile = viewPort.width <= 900;
  return (
    <Page title="Checkout" className="check_out">
      <DialogCardSuccess open={success2} setOpen={setSuccess2} />
      <Dialog
        header="Checkout Success"
        headerStyle={{
          textAlign: "center",
          zoom: "1",
          fontWeight: "800",
          paddingLeft: "50px",
          color: "#5d5365",
        }}
        visible={success}
        // visible={true}
        style={{
          border: "5px solid #5d5365",
          borderRadius: "10px",
          margin: "20px",
        }}
        onHide={() => {
          window.location.href = "/";
        }}
        breakpoints={{
          "1400px": { width: "50vw" },
          "960px": { width: "75vw" },
          "360px": { width: "10px" },
        }}
      >
        <CheckoutSuccess />
      </Dialog>
      <DeliveryForm
        setDelivery={setDelivery}
        user={user}
        setUser={setUser}
        delivery={delivery}
        checkNext={checkNext}
        selectShipping={data?.selectShipping}
        setCheckNext={setCheckNext}
        upgrade={upgrade}
      />

      <div className="check_out__payment">
        <div className="check_out__payment_pay">
          {!upgrade ? (
            <>
              {stripeStatus && (
                <>
                  <button
                    onClick={() => {
                      onPaymentStripe();
                    }}
                  >
                    <FaStripeS className="check_out__payment__icon" />
                    <p>Pay by Credit Card</p>
                  </button>
                  <p className="help_content">3% Credit Card Fee</p>
                </>
              )}
            </>
          ) : (
            <button
              onClick={() => {
                // onPaymentStripe();
                onUpgrade();
              }}
            >
              {/* <FaStripeS className="check_out__payment__icon" /> */}
              <p>Upgrade</p>
            </button>
            // <></>
          )}
        </div>
        {!upgrade ? (
          <>
            {data?.total > 0 ? (
              <>
                <div className="check_out__payment_pay">
                  <button
                    onClick={() => {
                      onPaymentBank();
                    }}
                  >
                    <BsBank2 className="check_out__payment__icon" />
                    <p>Pay by Bank Transfer or</p>
                    <img
                      style={{
                        marginLeft: "5px",
                        width: "50px",
                        height: "auto",
                      }}
                      src={paynowLogo}
                      alt="paynow_logo"
                    />
                  </button>
                </div>
                <div className="check_out__payment_pay">
                  <button
                    onClick={() => {
                      onPaymentCard();
                    }}
                  >
                    <p>Pay by Credit Card</p>
                  </button>
                </div>
              </>
            ) : (
              <div className="check_out__payment_pay">
                <button
                  onClick={() => {
                    onPaymentCard();
                  }}
                >
                  <p>Pay by Credit Card</p>
                </button>
              </div>
            )}
          </>
        ) : null}
      </div>

      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <Dialog
            header="Payment"
            visible={true}
            onHide={() => {
              setClientSecret("");
            }}
            style={{ width: isMobile ? "100vw" : "70vw" }}
            breakpoints={{
              "1400px": { width: "70vw" },
              "960px": { width: "75vw" },
              "641px": { width: "100vw" },
            }}
            footer={
              <div className="check_out__payment__footer">
                <div className="check_out__payment__footer__button">
                  <button
                    onClick={() => {
                      setClientSecret("");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            }
          >
            <div className="check_out_container">
              {data && (
                <div className="check_out_content">
                  {data?.bottles?.map((item, index) => {
                    if (["Dram Shop"].includes(item.type)) {
                      return (
                        <div key={index}>
                          <h3>Dram Shop</h3>
                          <ItemShop item={item} />
                        </div>
                      );
                    }
                    return (
                      <div key={index}>
                        <h3>Bottle</h3>
                        <ItemShop item={item} />
                      </div>
                    );
                  })}
                  {data?.drams?.map((item, index) => (
                    <div key={index} className="check_out_content__parentItem">
                      <h3>Auction</h3>
                      <h5>{formatDateTimeShort(item?.auction_end_date)}</h5>
                      {item?.items?.map((item, index) => (
                        <div key={index} className="check_out_content__item">
                          <div className="check_out_content__item__image">
                            <img src={item.image} alt="" />
                          </div>
                          <div className="check_out_content__item__info">
                            <div className="check_out_content__item__info__name">
                              {item.product_name}
                            </div>
                            <div className="check_out_content__item__info__price">
                              {formatCurrencySGD(item.price)}
                            </div>
                            <div className="check_out_content__item__info__quantity">
                              Quantity: {item.quantity}
                            </div>
                          </div>
                          <div className="check_out_content__item__total">
                            {formatCurrencySGD(item.price * item.quantity)}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                  <div
                    style={{
                      padding: "10px 0",
                      lineHeight: "1.5",
                    }}
                  >
                    {!upgrade && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span>Subtotal:</span>
                          <span>{formatCurrencySGD(data?.subtotal)}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span>Auction Commission:</span>
                          <span>{formatCurrencySGD(data?.buyerFee)}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span>Late Payment Fee:</span>
                          <span>{formatCurrencySGD(data?.paymentFee)}</span>
                        </div>
                      </>
                    )}

                    {upgrade && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px 0",
                          alignItems: "center",
                        }}
                      >
                        <span>Membership fee:</span>
                        <span>{formatCurrencySGD(data?.memberFee)}</span>
                      </div>
                    )}

                    {!upgrade && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>Delivery fee:</span>
                        <span>{formatCurrencySGD(data?.totalShiping)}</span>
                      </div>
                    )}

                    {fee && fee > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>3% Credit Card Fee:</span>
                        <span>{formatCurrencySGD(Number(fee))}</span>
                      </div>
                    ) : null}

                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontFamily: "LatoBold",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "LatoBold",
                        }}
                      >
                        Total amount payable:
                      </span>
                      <span
                        style={{
                          fontFamily: "LatoBold",
                        }}
                      >
                        {formatCurrencySGD(amount / 100)}
                      </span>
                    </div>
                  </div>

                  {delivery?.country && (
                    <div className="check_out__delivery">
                      <div className="check_out__delivery__title">
                        {upgrade ? "Details" : "Delivery Details"}
                      </div>
                      <div className="check_out__delivery__info">
                        <div className="check_out__delivery__info__name">
                          {delivery?.firstname && delivery?.firstname}{" "}
                          {delivery?.lastname && delivery?.lastname}{" "}
                        </div>
                        <div className="check_out__delivery__info__company__name">
                          {delivery?.companyname && delivery?.companyname}
                        </div>
                        <div className="check_out__delivery__info__address">
                          {["free-storage", "self"].includes(
                            data?.selectShipping
                          ) ? (
                            <span>Storage</span>
                          ) : (
                            <span>
                              {`${
                                delivery?.address ? delivery?.address + "," : ""
                              } 
                            ${delivery?.city ? delivery?.city + "," : ""}
                            ${delivery?.state ? delivery?.state + "," : ""}
                            ${delivery?.country ? delivery?.country + "," : ""}
                            ${
                              delivery?.postal_code ? delivery?.postal_code : ""
                            }`}
                            </span>
                          )}
                        </div>
                        <div className="check_out__delivery__info__phone">
                          {delivery.phone && delivery.phone}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* <div className="check_out__payment__footer__total">
                    <div className="check_out__delivery__total">
                      {data?.country}: {formatCurrencySGD(data?.totalShiping)}
                    </div>
                    Total: {formatCurrencySGD(amount / 100)}
                  </div> */}
                </div>
              )}
              <CheckoutForm />
            </div>
          </Dialog>
        </Elements>
      )}
      {free && (
        <Dialog
          header="Payment"
          visible={true}
          // style={{ width: "70vw" }}
          onHide={() => {
            setFree(null);
          }}
          breakpoints={{
            "1400px": { width: "70vw" },
            "960px": { width: "75vw" },
            "641px": { width: "100vw" },
          }}
          footer={
            <div className="check_out__payment__footer">
              <div className="check_out__payment__footer__total">Free</div>
              <div className="check_out__payment__footer__button">
                <button
                  onClick={() => {
                    setFree(null);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          }
        >
          <div className="check_out_container">
            <div className="check_out_content">
              {data?.bottles?.map((item, index) => {
                if (["Dram Shop"].includes(item.type)) {
                  return (
                    <div key={index}>
                      <h3>Dram Shop</h3>
                      <ItemShop item={item} />
                    </div>
                  );
                }
                return (
                  <div key={index}>
                    <h3>Bottle</h3>
                    <ItemShop item={item} />
                  </div>
                );
              })}
              {data?.drams?.map((item, index) => (
                <div key={index} className="check_out_content__parentItem">
                  <h3>Auction</h3>
                  <h5>{formatDateTimeShort(item?.auction_end_date)}</h5>
                  {item?.items?.map((item, index) => (
                    <div key={index} className="check_out_content__item">
                      <div className="check_out_content__item__image">
                        <img src={item.image} alt="" />
                      </div>
                      <div className="check_out_content__item__info">
                        <div className="check_out_content__item__info__name">
                          {item.product_name}
                        </div>
                        <div className="check_out_content__item__info__price">
                          {formatCurrencySGD(item.price)}
                        </div>
                        <div className="check_out_content__item__info__quantity">
                          Quantity: {item.quantity}
                        </div>
                      </div>
                      <div className="check_out_content__item__total">
                        {formatCurrencySGD(item.price * item.quantity)}
                      </div>
                    </div>
                  ))}
                </div>
              ))}

              <div className="check_out__delivery">
                <div className="check_out__delivery__title">Delivery</div>
                <div className="check_out__delivery__info">
                  <div className="check_out__delivery__info__name">
                    {delivery?.firstname} {delivery?.lastname}{" "}
                  </div>
                  <div className="check_out__delivery__info__company__name">
                    {delivery?.companyname}
                  </div>
                  <div className="check_out__delivery__info__address">
                    {`${delivery?.address}, ${delivery?.city}, ${delivery?.state}, ${delivery?.country}, ${delivery?.postal_code}`}
                  </div>
                  <div className="check_out__delivery__info__phone">
                    {delivery.phone}
                  </div>
                </div>
                <div className="check_out__delivery__total">
                  {data?.country}: {formatCurrencySGD(data?.totalShiping)}
                </div>
                <button onClick={() => onPaymentFree()}>Confirm</button>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </Page>
  );
}
