import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: null,
  status: null,
  data: null,
};

export const slice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    // has error
    setError: (state, action) => {
      state.error = action.payload;
    },

    setDataSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export default slice.reducer;
export const { setIsLoading, setError, setDataSuccess } = slice.actions;
