import React from "react";

export default function CheckoutSuccess() {
  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return { width };
  };
  const viewPort = useViewport();
  const isMobile = viewPort.width <= 700;
  return (
    <div
      style={{
        width: "80%",
        margin: "0 auto",
        textAlign: "center",
        color: "#5d5365",
      }}
    >
      {isMobile ? (
        <>
          <p
            style={{
              marginBottom: "10px",
              fontSize: "13px",
              fontFamily: "LatoLight",
            }}
          >
            Thank you for your payment!
          </p>
          <p style={{ fontSize: "13px", fontFamily: "LatoLight" }}>
            Your order has been received and is being processed
          </p>
          <p
            style={{
              marginBottom: "10px",
              fontSize: "13px",
              fontFamily: "LatoLight",
            }}
          >
            We will send payment information to your email address. If you have
            any question, please contact us at
          </p>
          <a
            style={{
              textDecoration: "none",
              marginTop: "10px",
              fontSize: "13px",
            }}
            href="mailto:enquiries@alcovault.com"
          >
            enquiries@alcovault.com
          </a>
        </>
      ) : (
        <>
          <p
            style={{
              marginBottom: "10px",
              fontFamily: "LatoLight",
            }}
          >
            Thank you for your payment!
          </p>
          <p>Your order has been received and is being processed</p>
          <p
            style={{
              marginBottom: "10px",
              fontFamily: "LatoLight",
            }}
          >
            We will send payment information to your email address. If you have
            any question, please contact us at
          </p>
          <a
            style={{
              textDecoration: "none",
              marginTop: "10px",
              fontFamily: "LatoLight",
            }}
            href="mailto:enquiries@alcovault.com"
          >
            enquiries@alcovault.com
          </a>
        </>
      )}
    </div>
  );
}
