import axiosClient from "./axiosClient";

const WhiskyCategoryApi = {
  getAll: () => {
    return axiosClient.get("/productcategory");
  },
  create: (data) => {
    return axiosClient.post("/productcategory", data);
  },
};

export default WhiskyCategoryApi;
