import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WalletApi from "api/wallet.api";

const initialState = {
  isloading: false,
  data: {},
};

export const WalletAsync = createAsyncThunk(
  "wallet/getWallet",
  async (payload, thunkAPI) => {
    let data = {};
    await WalletApi.getWalletByID(payload)
      .then((res) => {
        data = res.data;
      })
      .catch((err) => {
        data = err.response.data;
      });
    return data;
  }
);


export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    getWallet: (state, action) => {
      state.isloading = true;
    },
    updateWallet: (state, action) => {
      state.isloading = true;
      state.data = action.payload;
    },
  },
  extraReducers: {
    [WalletAsync.pending]: (state, action) => {
      state.isloading = true;
    },
    [WalletAsync.fulfilled]: (state, action) => {
      state.isloading = false;
      state.data = action.payload;
    },
    [WalletAsync.rejected]: (state, action) => {
      state.isloading = false;
    },
  },
});

export const { getWallet, updateWallet } = walletSlice.actions;
export const selectWallet = (state) => state.wallet.data;
export default walletSlice.reducer;