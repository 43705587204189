import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import translationEN from "./en";
// import translationVI from "./vi";
// import translationCN from "./cn";

// the translations
const resources = {
  US: {
    translation: translationEN,
  },
  // CN: {
  //   translation: translationCN,
  // },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "US",
    lng: "US",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
