export function isSale(price, price_sale) {
  let sale = false;

  if (price_sale) {
    if (price_sale < price && price_sale > 0) {
      sale = true;
    }
  }
  return sale;
}

export function isMember(role) {
  if (role === "member" || role === "admin") {
    return true;
  }
  return false;
}

export function isSuspension(role) {
  if (role === "suspended" || role === "banned") {
    return true;
  }
  return false;
}
