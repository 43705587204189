import axiosClient from "api/axiosClient";
import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import "./index.scss";

export default function Storage() {
  const location = useLocation();
  const sessionId = new URLSearchParams(location.search).get("session_id");
  const [message, setMessage] = React.useState("");

  const updateStorage = useCallback(async () => {
    try {
      if (sessionId) {
        const response = await axiosClient.get(`/storage/${sessionId}`);
        if (response._id) {
          setMessage("Success");
        } else {
          setMessage("Failed");
        }
      } else {
        setMessage("Failed");
      }
    } catch (error) {
      setMessage("Failed");
    }
  }, [sessionId]);

  useLayoutEffect(() => {
    updateStorage();
  }, [updateStorage]);
  return (
    <div id="storage_message">
      <h1
        style={{
          textAlign: "center",
        }}
      >
        {message === "Success" ? (
          <>
            Thank you. Your order has been received.
            <br /> We will notify you once your order has been
            shipped/delivered.
          </>
        ) : (
          message
        )}
      </h1>
    </div>
  );
}
