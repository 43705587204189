import { Dialog } from "primereact/dialog";
import React from "react";

function DialogCardSuccess({ open, setOpen }) {
  return (
    <Dialog
      header="Checkout Success"
      headerStyle={{
        textAlign: "center",
        zoom: "1",
        fontWeight: "800",
        paddingLeft: "50px",
        color: "#5d5365",
      }}
      visible={open}
      style={{
        border: "5px solid #5d5365",
        borderRadius: "10px",
        margin: "20px",
      }}
      onHide={() => {
        window.location.href = "/";
      }}
      breakpoints={{
        "1400px": { width: "50vw" },
        "960px": { width: "75vw" },
        "360px": { width: "10px" },
      }}
    >
      <div
        style={{
          width: "80%",
          margin: "0 auto",
          textAlign: "center",
          color: "#5d5365",
        }}
      >
        <p
          style={{
            marginBottom: "10px",
            fontFamily: "LatoLight",
          }}
        >
          Thank you for your Purchase
        </p>
        <p>Your order has been received and is being processed</p>
        <p
          style={{
            marginBottom: "10px",
            fontFamily: "LatoLight",
          }}
        >
          A Credit Card Payment link will be emailed to you by Paypal shortly
        </p>
        <a
          style={{
            textDecoration: "none",
            marginTop: "10px",
            fontFamily: "LatoLight",
          }}
          href="mailto:enquiries@alcovault.com"
        >
          enquiries@alcovault.com
        </a>
      </div>
    </Dialog>
  );
}

export default DialogCardSuccess;
