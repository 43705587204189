import axiosClient from "./axiosClient";

const ProductApi = {
  getProducts: () => {
    return axiosClient.get("/product");
  },
  getProductsByType: (type) => {
    return axiosClient.get(`/product/type/${type}`);
  },
  getProductsPagination: (page, limit) => {
    return axiosClient.get(`/product/pagination/${page}/${limit}`);
  },
  getProductsByTypePagination: (
    type,
    page,
    limit,
    query,
    sort,
    isSale,
    location
  ) => {
    return axiosClient.post(
      `/product/pagination/type/${type}/${page}/${limit}`,
      { query, sort, isSale, location }
    );
  },
  getProductById: (id) => {
    return axiosClient.get(`/product/${id}`);
  },
  getProductById2: (id) => {
    return axiosClient.get(`/product/${id}/2`);
  },
  addProduct: (product) => {
    return axiosClient.post("/product/create", product);
  },
  updateProduct: (id, product) => {
    return axiosClient.put(`/product/update/${id}`, product);
  },
  deleteProduct: (id) => {
    return axiosClient.delete(`/product/delete/${id}`);
  },
  getNameAllProduct: () => {
    return axiosClient.get("/product/name/all");
  },
  getProductByDistillery: (name) => {
    return axiosClient.get(`/product/distillery`, { name });
  },
};

export default ProductApi;
