import { createSlice } from "@reduxjs/toolkit";
import AuctionApi from "api/auction.api";
import axiosClient from "api/axiosClient";

const initialState = {
  isloading: false,
  data: [],
  auctions: [],
  auction_still: [],
  auction_previous: [],
  lives: [],
};

export const auctionSlice = createSlice({
  name: "auction",
  initialState,
  reducers: {
    getAuction: (state, action) => {
      state.isloading = true;
    },
    getAuctionStillSuccess: (state, action) => {
      state.auction_still = action.payload;
      state.isloading = false;
    },
    getAuctionPreviousSuccess: (state, action) => {
      state.auction_previous = action.payload;
    },
    getLivesSuccess: (state, action) => {
      state.lives = action.payload;
    },
  },
});

export const {
  getAuction,
  getAuctionStillSuccess,
  getAuctionPreviousSuccess,
  getLivesSuccess,
} = auctionSlice.actions;
export const selectAuction = (state) => state.auction.data;
export default auctionSlice.reducer;

export const getAuctionStill = (location) => async (dispatch) => {
  try {
    dispatch(getAuction());
    const res = await AuctionApi.getAuctionStill(location);
    dispatch(getAuctionStillSuccess(res || []));
  } catch (err) {
    dispatch(getAuctionStillSuccess([]));
  }
};

export const getAuctionPrevious = (location) => async (dispatch) => {
  try {
    const res = await AuctionApi.getAuctionPrevious(location);
    dispatch(getAuctionPreviousSuccess(res));
  } catch (err) {}
};

export const fetchLives = () => async (dispatch) => {
  try {
    const res = await axiosClient.get("/auction/live/location");
    if (res) {
      dispatch(getLivesSuccess(res));
    }
  } catch (error) {}
};
