export const setCookie = (name, value, expireTime) => {
  const expires =
    "expires=" + new Date(Date.now() + expireTime * 1000).toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";

  return;
};

export const getCookie = (cookieName) => {
  const name = cookieName + "=";
  const allCookies = document.cookie.split(";");

  for (let i = 0; i < allCookies.length; i++) {
    let itemCookie = allCookies[i];

    while (itemCookie.charAt(0) === " ") {
      itemCookie = itemCookie.substring(1);
    }

    if (itemCookie.indexOf(name) === 0) {
      return itemCookie.substring(name.length, itemCookie.length);
    }
  }

  return "";
};

export const deleteCookie = (cookieName) => {
  document.cookie =
    cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};
