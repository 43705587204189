import React from "react";
import { Helmet } from "react-helmet-async";

export default function Page({
  children,
  title = "",
  description = "",
  type = "website",
  name = "",
  image = "/logo.png",
  price = 0,
  ...other
}) {
  let desc = description.replace(/<[^>]+>/g, "");
  if (desc.length > 160) {
    desc = desc.substring(0, 160) + "...";
  }
  desc = desc.replace(/"/g, "'");
  desc = desc.replace(/\n/g, " ");
  desc = desc.replace(/\r/g, " ");
  desc = desc.replace(/\t/g, " ");
  desc = desc.replace(/&/g, "and");
  desc = desc.replace(/</g, "");
  desc = desc.replace(/>/g, "");

  return (
    <>
      <Helmet>
        <title>{`${title} | Alcovault Whisky Auction`}</title>
        <meta name="description" content={desc} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:type" content={type} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={image} />
        <meta property="og:image:alt" content={title} />
        <meta property="og:site_name" content="Alcovault" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@alcovault" />
        <meta name="twitter:creator" content="@alcovault" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:image:alt" content={title} />
        <meta name="twitter:domain" content="alcovault.com" />
        <meta name="twitter:app:name:iphone" content="Alcovault" />
        <meta name="twitter:app:name:ipad" content="Alcovault" />
        <meta name="twitter:app:name:googleplay" content="Alcovault" />
        <meta name="twitter:app:id:iphone" content="id1441399992" />
        <meta name="twitter:app:id:ipad" content="id1441399992" />
        <meta name="twitter:app:id:googleplay" content="com.alcovault" />
        <meta
          name="twitter:app:url:iphone"
          content="alcovault://alcovault.com"
        />
        <meta name="twitter:app:url:ipad" content="alcovault://alcovault.com" />
        <meta
          name="twitter:app:url:googleplay"
          content="alcovault://alcovault.com"
        />
        <meta name="twitter:app:country" content="SG" />

        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org/",
            "@type": "Product",
            "name": "${name}",
            "url": "${window.location.href}",
            "image": "${image}",
            "description": "${desc}",
            "priceCurrency": "SGD",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.7",
              "reviewCount": "5"
            }
            ${
              Number(price) > 0
                ? `,"offers": {
              "@type": "Offer",
              "priceCurrency": "SGD",
              "priceValidUntil": "2030-12-31",
              "price": "${price}.00",
              "availability": "http://schema.org/InStock",
              "seller": {
                "@type": "Organization",
                "name": "Alcovault"
              },
              "hasMerchantReturnPolicy": {
                "@type": "MerchantReturnPolicy",
                "name": "Alcovault Return Policy",
                "url": "https://alcovault.com/return-policy"
              },
              "shippingDetails": {
                "@type": "OfferShippingDetails",
                "deliveryTime": "P3D",
                "shippingDestination": {
                  "@type": "DefinedRegion",
                  "addressCountry": "SG"
                },
                "shippingLabel": "Free Shipping"
              }
            }`
                : ""
            }
          }
        `}
        </script>
      </Helmet>
      <div {...other}>{children}</div>
    </>
  );
}
