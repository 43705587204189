import axiosClient from "./axiosClient";

const WishlistApi = {
  getAll: () => {
    return axiosClient.get("/wishlist");
  },
  getById: (id) => {
    return axiosClient.get("/wishlist/" + id);
  },
  change: (data) => {
    return axiosClient.post("/wishlist/create/", data);
  },
};

export default WishlistApi;
