import axiosClient from "api/axiosClient";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  independent: {},
  independents: [],
};

const slice = createSlice({
  name: "independent",
  initialState,
  reducers: {
    setIndependent: (state, action) => {
      state.independent = action.payload;
    },
    setIndependents: (state, action) => {
      state.independents = action.payload;
    },
  },
});

export const { setIndependent, setIndependents } = slice.actions;
export default slice.reducer;

export const getIndependent = (id) => async (dispatch) => {
  try {
    const res = await axiosClient.get(`/independent/${id}`);
    dispatch(setIndependent(res));
  } catch (error) {}
};

export const getIndependents = () => async (dispatch) => {
  try {
    const res = await axiosClient.get(`/independent`);
    dispatch(setIndependents(res));
  } catch (error) {}
};
