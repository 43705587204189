import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useNavigate } from "react-router";
import "./index.scss";
import {
  changePathProductDetail,
  changePathProductDetail2,
} from "utils/changePath";
import axiosClient from "api/axiosClient";
import ProductSearchSkeleton from "./ProductSearchSkeleton";
import { changePathProductLiveAuction } from "utils/changePath";
import { changePathProductPreviousAuction2 } from "utils/changePath";
import { formatCurrencySGD } from "utils/formatNumber";
import { Link } from "react-router-dom";

export default function ProductSearch({ search = "", selectSearch = "all" }) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSearchProduct = useCallback(async () => {
    if (search === "") return;

    let res = [];
    let data = [];
    const [key, location] = selectSearch.split(" ");
    if (["Dram", "Bottle", "Cask"].includes(key)) {
      let query = {
        prod_name: { $regex: search, $options: "i" },
        prod_type: key + " Shop",
        prod_location_of_bottle: location,
      };
      res = await axiosClient.post(`/product/search`, {
        query: query,
      });
      data = res;
    } else if (["live", "previous"].includes(key)) {
      res = await axiosClient.post(`/auction/search/product`, {
        search: search,
        type: key,
        location: location,
      });

      data = res.map((item) => {
        return {
          _id: item.product,
          prod_name: item.name,
          avatar_photo_prod: item.image,
          ...item,
        };
      });
    } else {
      let query = {
        prod_name: { $regex: search, $options: "i" },
        prod_type: { $ne: "Auction" },
      };
      const res1 = axiosClient.post(`/product/search`, {
        query: query,
      });

      let res2 = axiosClient.post(`/auction/search/product`, {
        search: search,
        type: "live",
      });

      let res3 = axiosClient.post(`/auction/search/product`, {
        search: search,
        type: "previous",
      });

      res = await Promise.all([res1, res2, res3]);

      data = res.flat().map((item) => {
        if (item.product) {
          return {
            _id: item.product,
            prod_name: item.name,
            avatar_photo_prod: item.image,
            ...item,
          };
        }
        return item;
      });
    }
    setProducts(data);
    setLoading(false);
  }, [search, selectSearch]);

  useEffect(() => {
    setLoading(true);

    const timeout = setTimeout(async () => {
      getSearchProduct();
    }, 300);

    return () => clearTimeout(timeout);
  }, [getSearchProduct]);

  const navigate = useNavigate();

  const productNavigate = (id, name, location) => {
    // return changePathProductDetail(id, name);
    return changePathProductDetail2(id, name, location);
  };

  const productLink = (
    auctionId,
    productId,
    prodName,
    location,
    auction_status
  ) => {
    if (auctionId) {
      if (auction_status === "completed") {
        return changePathProductPreviousAuction2(
          auctionId,
          productId,
          prodName,
          location
        );
      } else {
        return changePathProductLiveAuction(
          auctionId,
          productId,
          prodName,
          location
        );
      }
    } else if (!auctionId) {
      return changePathProductDetail2(productId, prodName, location);
    }
  };

  return (
    <>
      <div className="list_prod_search">
        {loading && (
          <div className="product-search-loading">
            <div className="loading"></div>
          </div>
        )}
        {products.length > 0 ? (
          products.map((product, index) => {
            if (!product) return <></>;
            let location = "";
            let key = "";
            if (product?.auction_id) {
              location = product?.auction_location;
              key =
                product?.auction_status === "completed"
                  ? "Previous Auction"
                  : "Live Auction";
            } else {
              location = product?.prod_location_of_bottle;
              key = product?.prod_type;
            }
            let price = product?.prod_price;
            if (price === "auction_end") {
              price = "Auction Ended";
            } else {
              if (product?.prod_price_sale && product?.prod_price_sale > 0) {
                if (product?.prod_price_sale < product?.prod_price) {
                  price = formatCurrencySGD(product?.prod_price_sale);
                }
              } else {
                price = formatCurrencySGD(price);
              }
            }
            return (
              <Link
                to={productLink(
                  product?.auctionId,
                  product?.id || product?.product_id,
                  product?.prod_name,
                  product?.auction_location || product?.prod_location_of_bottle,
                  product?.auction_status
                )}
                className="prod_search_item"
                key={product?._id || index}
              >
                <div className="prod_search_img">
                  <img src={product?.avatar_photo_prod} alt="product" />
                </div>
                <div className="prod_search_info">
                  <p className="prod_search_name">{product?.prod_name}</p>
                  <p className="prod_search_price">{price}</p>
                  <p className="prod_search_location">
                    {key} - {location}
                  </p>
                </div>
              </Link>
            );
          })
        ) : (
          <div
            style={{
              textAlign: "left",
              padding: "10px 0",
              color: "#999",
              fontSize: "14px",
            }}
          >
            No result
          </div>
        )}
      </div>
    </>
  );
}
