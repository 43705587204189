import { PATHS } from "routes/paths";
import { formatLinkName } from "./formatLink";

// single product and review
export function changePathProductDetail(product_id, name) {
  return PATHS.product?.root + "/" + formatLinkName(name) + "-i-" + product_id;
}

export function changePathProductDetail2(
  product_id,
  name,
  location = "singapore"
) {
  location = location.toLowerCase();
  return (
    PATHS.bottleshop?.root +
    "/" +
    location +
    "/" +
    product_id +
    "/" +
    formatLinkName(name)
  );
}

export function changePathDram(product_id, name, location = "singapore") {
  location = location.toLowerCase();
  return (
    PATHS.dram?.root +
    "/" +
    location +
    "/" +
    product_id +
    "/" +
    formatLinkName(name)
  );
}

export function changePathCask(product_id, name) {
  return PATHS.caskShop?.root + "/" + product_id + "/" + formatLinkName(name);
}
//single product and review has auction
export function changePathProductDetailAuction(
  product_id,
  auction_id,
  product_name,
  auction_name
) {
  return (
    PATHS.product?.root +
    "/" +
    formatLinkName(product_name) +
    "-i-" +
    product_id +
    "/" +
    formatLinkName(auction_name) +
    "-i-" +
    auction_id
  );
}

export function changePathProductLiveAuction(
  auction_id,
  product_id,
  product_name,
  location = "singapore"
) {
  location = location.toLowerCase();
  return (
    PATHS.whiskyAuction?.root +
    "/" +
    location +
    "/" +
    auction_id +
    "-" +
    product_id +
    "/" +
    formatLinkName(product_name)
  );
}

export function changePathProductPreviousAuction2(
  auction_id,
  product_id,
  product_name,
  location = "singapore"
) {
  location = location.toLowerCase();
  return (
    PATHS.previousAuctions?.root +
    "/" +
    location +
    "/" +
    auction_id +
    "-" +
    product_id +
    "/" +
    formatLinkName(product_name)
  );
}

export function changePathProductPreviousAuction(
  product_id,
  auction_id,
  product_name,
  auction_name
) {
  return (
    PATHS.product?.root +
    "/" +
    formatLinkName(product_name) +
    "-i-" +
    product_id +
    "/" +
    formatLinkName(auction_name) +
    "-i-" +
    auction_id +
    "/previous-auction"
  );
}
// single dram and review
export function changePathDramDetail(dram_id) {
  return PATHS.dram?.root + "/" + dram_id;
}
//single dram and review has auction
export function changePathDramDetailAuction(dram_id, auction_id) {
  return PATHS.dram?.root + "/" + auction_id + "/" + dram_id;
}

export function changePathPrevious(previous_id, location) {
  return PATHS.previousAuctions.root + "/" + location + "/" + previous_id;
}

export function changePathNewsDetail(news_id) {
  return PATHS.newsAndArticles?.root + "/" + news_id;
}

export function changePathNewsUpdate(news_id) {
  return PATHS.newsAndArticles?.submit + "/" + news_id;
}

export function changePathNewsCreate() {
  return PATHS.newsAndArticles?.submit;
}

export function changePathCart() {
  return PATHS.cart.root;
}
