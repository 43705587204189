import AuthAPI from "api/auth.api";
import { deleteCookie } from "config/cookies";
import { getCookie } from "config/cookies";
import React, { useCallback, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "redux/modules/authenticate";
import { profileSuccess } from "redux/modules/authenticate";
import { getCartOther } from "redux/modules/cart";
import { setDelivery } from "redux/modules/country";
import { GetOrdersByUser } from "redux/modules/order";
import { WalletAsync } from "redux/modules/wallet";

function Authentication() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const fetchWallet = useCallback(async () => {
    if (!user?._id) return;
    dispatch(WalletAsync(user?._id));
  }, [dispatch, user]);

  useLayoutEffect(() => {
    fetchWallet();
  }, [fetchWallet]);

  const fetchMe = useCallback(async () => {
    try {
      const cookie = getCookie("user");
      if (!cookie || cookie === "undefined") {
        deleteCookie("user");
        return;
      }
      const res = await AuthAPI.getMe(JSON.parse(getCookie("user"))._id);
      if (res?.status === "success") {
        dispatch(WalletAsync(res?.user?._id));
        dispatch(GetOrdersByUser(res?.user?._id));
        dispatch(getCartOther());
        dispatch(profileSuccess(res));
        dispatch(setDelivery(res?.user?.country_mb));
      } else {
        dispatch(clearUser());
      }
    } catch (error) {
      // setUser(null);
      dispatch(clearUser());
    }
  }, [dispatch]);

  useLayoutEffect(() => {
    fetchMe();
  }, [fetchMe]);

  return <div></div>;
}

export default Authentication;
