import React, { Suspense } from "react";
import ReactDOM, { hydrateRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import store from "./redux/store";
import i18n from "./translations/i18n";
import App from "./App";
// import "./App.css";
import "reactjs-popup/dist/index.css";
import "tippy.js/dist/tippy.css"; // optional for styling
import "react-loading-skeleton/dist/skeleton.css";
// import "react-quill/dist/quill.snow.css";
// import "react-quill/dist/quill.core.css";
// import "react-quill/dist/quill.bubble.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter } from "react-router-dom";
import config from "config/config";
import { HelmetProvider } from "react-helmet-async";
import Authentication from "components/Authentication";
import Notification from "components/Notification";
import { Loadable } from "routes";
import { ToastContainer } from "react-toastify";
// const App = Loadable(React.lazy(() => import("./App")));

const helmetContext = {};

const APP = (
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider context={helmetContext}>
        <GoogleOAuthProvider clientId={config.google_oauth.client_id}>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <Authentication />
              <Notification />
              <App />

              <ToastContainer
                style={{
                  width: "auto",
                  height: "auto",
                }}
              />
            </BrowserRouter>
          </I18nextProvider>
        </GoogleOAuthProvider>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);
if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, APP);
} else {
  root.render(APP);
}
