import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProductApi from "api/product.api";

const initialState = {
  isloading: false,
  data: [],
  drams: [],
  nextDrams: {},
  previousDrams: {},
  totalDrams: 0,
  bottles: [],
  nextBottles: {},
  previousBottles: {},
  totalBottles: 0,
};

export const ProductAsync = createAsyncThunk(
  "product/getProduct",
  async (payload, thunkAPI) => {
    let data = {};
    await ProductApi.getProducts()
      .then((res) => {
        data = res.data;
      })
      .catch((err) => {});
    return data;
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    getProduct: (state, action) => {
      state.isloading = true;
    },
    getDramsSuccess: (state, action) => {
      state.isloading = false;
      state.drams = action.payload.results;
      state.nextDrams = action.payload.next;
      state.previousDrams = action.payload.previous;
      state.totalDrams = action.payload.total;
    },
    getBottlesSuccess: (state, action) => {
      state.isloading = false;
      state.bottles = action.payload.results;
      state.nextBottles = action.payload.next;
      state.previousBottles = action.payload.previous;
      state.totalBottles = action.payload.total;
    },
    addDrams: (state, action) => {
      state.drams.push(action.payload);
    },
    addBottles: (state, action) => {
      state.bottles.push(action.payload);
    },
  },
  extraReducers: {
    [ProductAsync.pending]: (state, action) => {
      state.isloading = true;
    },
    [ProductAsync.fulfilled]: (state, action) => {
      state.isloading = false;
      state.data = action.payload;
    },
    [ProductAsync.rejected]: (state, action) => {
      state.isloading = false;
    },
  },
});

export const {
  getProduct,
  getDramsSuccess,
  getBottlesSuccess,
  addDrams,
  addBottles,
} = productSlice.actions;
export const selectProduct = (state) => state.product.data;
export default productSlice.reducer;

export const fetchDrams = () => async (dispatch) => {
  try {
    dispatch(getProduct());
    const results = await ProductApi.getProductsByTypePagination(
      "Dram Shop",
      1,
      20
    );
    dispatch(getDramsSuccess(results));
  } catch (error) {}
};

export const fetchDramsPagination = (page) => async (dispatch) => {
  try {
    dispatch(getProduct());
    const { results } = await ProductApi.getProductsByTypePagination(
      "Dram Shop",
      page,
      100
    );
    dispatch(getDramsSuccess(results));
  } catch (error) {}
};

export const fetchBottles = () => async (dispatch) => {
  try {
    dispatch(getProduct());
    const results = await ProductApi.getProductsByTypePagination(
      "Bottle Shop",
      1,
      20
    );
    dispatch(getBottlesSuccess(results));
  } catch (error) {}
};

export const fetchBottlesPagination = (page) => async (dispatch) => {
  try {
    dispatch(getProduct());
    const { results } = await ProductApi.getProductsByTypePagination(
      "Bottle Shop",
      page,
      100
    );
    dispatch(addBottles(results));
  } catch (error) {}
};
