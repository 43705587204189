import axiosClient from "./axiosClient";

const NewletterApi = {
  getNewlettersAll: () => {
    return axiosClient.get("/newletter");
  },
  getNewlettersAllStatus: () => {
    return axiosClient.get("/newletter/status/active");
  },
  getNewLetterUser: (id) => {
    return axiosClient.get(`/newletter/user/${id}`);
  },

  getNewLetterDetail: (id) => {
    return axiosClient.get(`/newletter/${id}`);
  },
  getNewCommentInNewLetter: (id) => {
    return axiosClient.get(`/newletter/${id}/comment`);
  },
  deleteNewLetter: (id) => {
    return axiosClient.delete(`/newletter/delete/${id}`);
  },
  createNewletter: (data) => {
    return axiosClient.post("/newletter", data);
  },
  createViewNewletter: (id) => {
    return axiosClient.post(`/newletter/view/${id}`);
  },
  createNewCommentInNewLetter: (id, data) => {
    return axiosClient.post(`/newletter/${id}/comment`, data);
  },
  addLikeInComment: (id, data) => {
    return axiosClient.put(`/newletter/comment/${id}/addlike`, data);
  },
  addSubComment: (id, id_comment, data) => {
    return axiosClient.post(
      `/newletter/${id}/comment/${id_comment}/subcomment`,
      data
    );
  },
  getSubComment: (id, id_comment) => {
    return axiosClient.post(
      `/newletter/${id}/comment/${id_comment}/subcomment`
    );
  },
  getMostView: (number) => {
    return axiosClient.get(`/newletter/mostviewed?length=${number}`);
  },
};

export default NewletterApi;
