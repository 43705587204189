import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { getCartOther } from "redux/modules/cart";
import { updateQuantityMinus } from "redux/modules/cart";
import { updateQuantity } from "redux/modules/cart";
import { changePathProductDetail } from "utils/changePath";
import { formatCurrencySGD } from "utils/formatNumber";
import FunctionDown from "../FunctionDown";
import { IoClose } from "react-icons/io5";
import { removeFromCart } from "redux/modules/cart";
import { removeProduct } from "redux/modules/order";
import { confirmAlert } from "react-confirm-alert";
import { getCookie } from "config/cookies";

function BottlesLocal({
  item,
  ratings,
  selectCarts,
  setSelectCarts,
  cartsOther,
  setCartsOther,
  total,
  setTotal,
  functionDown,
  setFunctionDown,
  setSelectAll,
  type,
}) {
  const dispatch = useDispatch();

  const isSale = (item) => {
    let sale = false;

    if (!item) {
      return sale;
    }

    if (item.prod_price_sale !== 0 && item.prod_price_sale) {
      if (item.prod_price_sale < item.prod_price) {
        sale = true;
      }
    }
    return sale;
  };
  const user = getCookie("user");

  const handleRemoveBottle = (productItem_id) => {
    if (type) {
      confirmAlert({
        title: "Confirm to remove product",
        message: "Are you sure to remove this product?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              dispatch(removeFromCart(productItem_id));
              dispatch(getCartOther());
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } else {
      let user_id = "";
      if (user !== "" && user !== undefined) {
        user_id = JSON.parse(user)?._id;
      }
      confirmAlert({
        title: "Confirm to remove product",
        message: "Are you sure to remove this product?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              dispatch(removeProduct(user_id, productItem_id));
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    }
  };
  const navigate = useNavigate();

  const handleNavigate = (id, name) => {
    navigate(changePathProductDetail(id, name));
  };

  const isInventory = item?.inventory && item?.inventory > 0 ? true : false;

  return (
    <React.Fragment>
      <tr
        style={{
          opacity: isInventory ? 1 : 0.3,
        }}
      >
        <td>
          <div className="cart_page_content_table_checkbox">
            <input
              type="checkbox"
              name="checkbox"
              id="checkbox"
              disabled={!isInventory}
              checked={selectCarts.includes(item?._id)}
              onChange={() => {
                setSelectAll("change");
                let number_of_bottles = item?.number_of_bottles || 1;
                const isSelected = selectCarts.includes(item?._id);
                const price = isSale(item)
                  ? item?.prod_price_sale
                  : item?.prod_price;
                const quantity = item?.quantity || 1;

                if (isSelected) {
                  setSelectCarts(
                    selectCarts.filter((cartId) => cartId !== item?._id)
                  );

                  setCartsOther((prevState) => ({
                    ...prevState,
                    lengthDram:
                      item?.prod_type === "Dram Shop" ||
                      item?.prod_type === "Bottle Share Drams"
                        ? prevState.lengthDram - quantity * number_of_bottles
                        : prevState.lengthDram,
                    lengthBottle:
                      item?.prod_type !== "Dram Shop" ||
                      item?.prod_type === "Bottle Share Drams"
                        ? prevState.lengthBottle - quantity * number_of_bottles
                        : prevState.lengthBottle,
                    length: prevState.length - quantity * number_of_bottles,
                    total: prevState.total - price * quantity,
                  }));
                } else {
                  setSelectCarts([...selectCarts, item?._id]);
                  setCartsOther((prevState) => ({
                    ...prevState,
                    lengthDram:
                      item?.prod_type === "Dram Shop"
                        ? prevState.lengthDram + quantity * number_of_bottles
                        : prevState.lengthDram,
                    lengthBottle:
                      item?.prod_type !== "Dram Shop"
                        ? prevState.lengthBottle + quantity * number_of_bottles
                        : prevState.lengthBottle,
                    length: prevState.length + quantity * number_of_bottles,
                    total: prevState.total + price * quantity,
                  }));
                }
              }}
            />
          </div>
        </td>
        <td>
          <div
            onClick={() => handleNavigate(item?._id, item?.prod_name)}
            className="cart_page_content_table_product"
          >
            <div className="cart_page_content_table_product_img">
              <img src={item?.avatar_photo_prod} alt="" />
            </div>
            <div className="cart_page_content_table_product_info">
              <h4>{item?.prod_name}</h4>
              <p>
                Lot Id:{" "}
                {item?.product?.id ||
                  item?.product?._id.toString().substring(16)}
              </p>
            </div>
          </div>
        </td>
        <td>
          <div className="cart_page_content_table_price">
            <span>
              {isSale(item) ? (
                <>
                  <span className="price_sale">
                    {formatCurrencySGD(item.prod_price_sale)}
                  </span>{" "}
                  -{" "}
                  <span className="price_original_sale">
                    {formatCurrencySGD(item.prod_price)}
                  </span>
                </>
              ) : (
                <span className="price_original">
                  {formatCurrencySGD(item.prod_price)}
                </span>
              )}
            </span>
          </div>
        </td>
        <td>
          <div className="cart_page_content_table_quantity">
            <div className="cart_page_content_table_quantity_btn">
              <button
                onClick={() => {
                  if (item.quantity === 1) {
                    if (!window.confirm("Do you want to remove this item?")) {
                      return;
                    }
                  }
                  dispatch(updateQuantityMinus(item._id));
                  dispatch(getCartOther());
                }}
              >
                -
              </button>
            </div>
            <div className="cart_page_content_table_quantity_input">
              <input disabled type="text" value={item.quantity} />
            </div>
            <div className="cart_page_content_table_quantity_btn">
              <button
                onClick={() => {
                  dispatch(updateQuantity(item._id));
                  dispatch(getCartOther());
                }}
              >
                +
              </button>
            </div>
          </div>
        </td>
        <td>
          <div className="cart_page_content_table_total">
            <span>
              {isSale(item)
                ? formatCurrencySGD(item.prod_price_sale * item.quantity)
                : formatCurrencySGD(item.prod_price * item.quantity)}
            </span>
          </div>
        </td>
        <td>
          <div className="cart_page_content_table_auction">
            <span>{item.prod_type}</span>
          </div>
        </td>
        <td className="func_down">
          <IoClose
            onClick={() => {
              handleRemoveBottle(item._id);
              // if (functionDown === item.product._id) {
              //   setFunctionDown("");
              // } else {
              //   setFunctionDown(item.product._id);
              // }
            }}
            className="icon"
          />
          {/* <FaAngleDown
            onClick={() => {
              if (functionDown === item._id) {
                setFunctionDown("");
              } else {
                setFunctionDown(item._id);
              }
            }}
            className="icon"
          />
          <FunctionDown
            isShow={item.isShow}
            functionDown={functionDown}
            setFunctionDown={setFunctionDown}
            product_id={item._id}
            type={"local"}
          /> */}
        </td>
      </tr>
    </React.Fragment>
  );
}

export default React.memo(BottlesLocal);
