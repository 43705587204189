const config = {
  // base_url: 'http://localhost:5000',

  // base_url: "http://localhost:3333/",
  // base_server_url: "http://localhost:3333/",
  base_url: import.meta.env.VITE_SERVER_API,
  base_server_url: import.meta.env.VITE_SERVER_API,
  share_url: import.meta.env.VITE_SERVER_SHARE,
  stripe: {
    public: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    status: import.meta.env.VITE_STRIPE_STATUS,
  },
  recaptcha: {
    sitekey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
  },
  facebook_url: "https://graph.facebook.com/?fields=engagement&id=",
  page_facebook_id: import.meta.env.VITE_PAGE_FACEBOOK_ID,
  google_oauth: {
    client_id: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID,
  },
  instagram_url: "https://www.instagram.com",
  twitter_url: "https://dev.twitter.com/1.1/search/tweets.json?q=",
};

export default config;
