import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RatingApi from "api/rating.api";

const initialState = {
  isloading: false,
  data: [],
};

export const RatingCountAsync = createAsyncThunk(
  "rating/getRatingCount",
  async (payload, thunkAPI) => {
    let data = {};
    await RatingApi.getRatingCount()
      .then((res) => {
        data = res;
      })
      .catch((err) => {});
    return data;
  }
);

export const RatingSlice = createSlice({
  name: "rating",
  initialState,
  reducers: {
    getRatingCount: (state, action) => {
      state.isloading = true;
    },
  },
  extraReducers: {
    [RatingCountAsync.pending]: (state, action) => {
      state.isloading = true;
    },
    [RatingCountAsync.fulfilled]: (state, action) => {
      state.isloading = false;
      state.data = action.payload;
    },
    [RatingCountAsync.rejected]: (state, action) => {
      state.isloading = false;
    },
  },
});

export const { getRatingCount } = RatingSlice.actions;
export const selectRatingCount = (state) => state.rating.data;
export default RatingSlice.reducer;
