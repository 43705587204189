import { createSlice } from "@reduxjs/toolkit";
import ProductApi from "api/product.api";

const initialState = {
  isloading: false,
  error: null,
  data: [],
  product: {},
};

export const shopSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.isloading = true;
    },
    hasErrors: (state, action) => {
      state.isloading = false;
      state.error = action.payload;
    },
    getProductByIdSuccess: (state, action) => {
      state.isloading = false;
      state.product = action.payload;
    },
    getDramsShopSuccess: (state, action) => {
      state.isloading = false;
      state.data = action.payload;
    },
    getBottlesShopSuccess: (state, action) => {
      state.isloading = false;
      state.data = action.payload;
    },
  },
});

export const {
  startLoading,
  hasErrors,
  getProductByIdSuccess,
  getDramsShopSuccess,
  getBottlesShopSuccess,
} = shopSlice.actions;

export default shopSlice.reducer;

export const getDramsShop = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await ProductApi.getProductsByType("Dram Shop");
    dispatch(getDramsShopSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getDramsPaginationShop = (page, limit) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await ProductApi.getProductsByTypePagination(
      "Dram Shop",
      page,
      limit
    );
    dispatch(getDramsShopSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getBottlesShop = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await ProductApi.getProductsByType("Bottle Shop");
    dispatch(getBottlesShopSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getBottlesPaginationShop = (page, limit) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await ProductApi.getProductsByTypePagination(
      "Bottle Shop",
      page,
      limit
    );
    dispatch(getBottlesShopSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getProductById = (id) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await ProductApi.getProductById(id);
    dispatch(getProductByIdSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};
