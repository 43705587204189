import React, { useState } from "react";
import "./App.css";
import "./App.scss";
import "fontface1.scss";

import ScrollToTop from "react-scroll-to-top";
import Router from "routes";
import HeaderTop from "components/HeaderTop";
import { useSelector } from "react-redux";
// import HeaderVerifyEmail from "components/HeaderVerifyEmail";
import { HiChevronUp } from "react-icons/hi";
import useViewport from "hooks/ViewPort";
// import SidebarMobile from "features/MyAccount/components/Navbar/SidebarMobile";

// const HeaderTop = React.lazy(() => import("components/HeaderTop"));
const HeaderVerifyEmail = React.lazy(() =>
  import("components/HeaderVerifyEmail")
);
const SidebarMobile = React.lazy(() =>
  import("features/MyAccount/components/Navbar/SidebarMobile")
);

const CustomIcon = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <HiChevronUp size={24} style={{ color: "white" }} />
    </div>
  );
};
function App() {
  return (
    <div>
      <HeaderTop />

      <HeaderVerifyEmail />
      {/* <RouterVN country={country} /> */}
      <Router />

      <SidebarMobile />

      <ScrollToTop
        smooth
        top={30}
        className="layout-scroll-to-top"
        id="layout-scroll-to-top"
        // style={{
        //   backgroundColor: "#927853",
        //   width: "40px",
        //   height: "40px",
        //   borderRadius: "50%",
        //   marginBottom: "80px",
        //   marginRight: "2.5em",
        // }}
        component={<CustomIcon />}
        // viewBox={<FaAngleUp />}
        // svgPath={<FaAngleUp />}
      />
    </div>
  );
}

export default App;
