import React, { useCallback, useEffect, useLayoutEffect } from "react";
import Select from "react-select";
import "./index.scss";
import { FaLocationArrow, FaTruckMoving } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setCountry } from "redux/modules/country";
import { setDelivery } from "redux/modules/country";
import { fetchGeoIp } from "redux/modules/country";
import { deleteCookie, getCookie } from "config/cookies";
import { Link, useLocation } from "react-router-dom";
import {
  BsBasket,
  BsCalculator,
  BsFacebook,
  BsInstagram,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";
import Tippy from "@tippyjs/react";
import { FaUserAlt, FaWallet } from "react-icons/fa";
import { selectWallet } from "redux/modules/wallet";
import navConfig from "../../Layout/Singapore/NavConfig";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";
import { selectAuth } from "redux/modules/authenticate";
import { WalletAsync } from "redux/modules/wallet";
import { Dialog } from "primereact/dialog";
// import DialogCalculator from "./DialogCalculator";
import useViewport from "hooks/ViewPort";
import { GetOrdersByUser } from "redux/modules/order";
import { getCartOther } from "redux/modules/cart";
import { formatCurrencySGD } from "utils/formatNumber";
import i18next from "i18next";
import StarRatings from "react-star-ratings";
import axiosClient from "api/axiosClient";
import { PATHS } from "routes/paths";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const DialogCalculator = React.lazy(() => import("./DialogCalculator"));

export default function HeaderTop() {
  const dispatch = useDispatch();
  const [lang, setLang] = React.useState();
  const { user } = useSelector((state) => state.auth);
  const [visible, setVisible] = React.useState(false);
  const { t, i18n } = useTranslation();
  const [infoReview, setInfoReview] = React.useState({
    count: 0,
    service: 0,
    price: 0,
    delivery: 0,
    quantity: 0,
    website: 0,
    overall: 0,
  });

  const { country, delivery, countries, website } = useSelector(
    (state) => state.country
  );

  useEffect(() => {
    if (localStorage.getItem("lang")) return;
    if (country === "china") {
      setLang("CN");
    }
  }, [country]);

  useLayoutEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useLayoutEffect(() => {
    i18next.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  }, [lang]);

  const wallet = useSelector(selectWallet);

  const { others } = useSelector((state) => state.order);
  const { others: cartsOther, carts } = useSelector((state) => state.cartlocal);
  const location = useLocation();

  useEffect(() => {
    dispatch(getCartOther());
  }, [dispatch]);

  const handleChangeDeliveryCountry = (value) => {
    dispatch(setDelivery(value.label));
  };

  useLayoutEffect(() => {
    if (!user?._id) return;
    dispatch(GetOrdersByUser(user?._id));
  }, [dispatch, user]);

  useLayoutEffect(() => {
    dispatch(fetchGeoIp());
  }, [dispatch]);

  const handleLogout = () => {
    deleteCookie("user");
    document.location.href = "/";
  };
  const { width } = useViewport();
  const isMobile = width < 768;

  const fetchReview = useCallback(async () => {
    try {
      const data = await axiosClient.get("/rating/info");
      if (data) {
        // chuyển thành 1 số có 1 chữ số sau dấu phẩy

        setInfoReview({
          count: data.count,
          service: data.service.toFixed(1),
          price: data.price.toFixed(1),
          delivery: data.delivery.toFixed(1),
          quantity: data.quantity.toFixed(1),
          website: data.website.toFixed(1),
          overall: data.overall.toFixed(1),
        });
      }
    } catch (err) {}
  }, []);

  useLayoutEffect(() => {
    fetchReview();
  }, [fetchReview]);
  return (
    <div className="header-top">
      <div className="container">
        <div className="country">
          <div className="item item1">
            <div className="label">
              <FaTruckMoving
                style={{
                  fontSize: isMobile ? "30px" : "1.2rem",
                  marginRight: "0.5rem",
                  color: "#084392",
                }}
              />
              <span className="country-title">
                {t("headerTop.current_delivery_to")}
              </span>
            </div>
            <Select
              className="select-country"
              classNamePrefix="select"
              styles={{
                control: (provided) => ({
                  ...provided,
                  boxShadow: "none",
                  height: isMobile ? "1rem" : "2rem",
                  minHeight: isMobile ? "1rem" : "2rem",
                  width: "10rem",
                  color: "#084392",
                  backgroundColor: "transparent",
                  border: "1px solid #084392",
                }),
                option: (provided) => ({
                  ...provided,
                  color: "#084392",
                  backgroundColor: "white",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "#084392",
                  backgroundColor: "transparent",
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  color: "transparent",
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  backgroundColor: "white",
                }),
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: "white",
                }),
              }}
              isSearchable={true}
              options={countries}
              getOptionValue={(option) => option.label}
              getOptionLabel={(option) => option.label}
              value={{ label: delivery }}
              defaultValue={delivery}
              onChange={handleChangeDeliveryCountry}
            />
          </div>
          <div className="subitem">
            <div className="item2" onClick={() => setVisible(true)}>
              <span className="delivery-title">
                {t("headerTop.delivery_calc")}
              </span>
              <BsCalculator
                style={{
                  fontSize: isMobile ? "30px" : "1.2rem",
                  marginRight: "0.5rem",
                  color: "#084392",
                }}
              />
            </div>
          </div>
        </div>
        <div className="header-top-sign-top">
          {user?.username ? (
            <div className="sign_inup">
              <Link to="/my-account">
                <FaUserAlt style={{ marginRight: "10px" }} />
                <span className="username">{user?.username}</span>
              </Link>
              <ul className="list_user_func">
                <li>
                  <Link to="/my-account">{t("header.profile")}</Link>
                </li>
                <li>
                  <Link onClick={() => handleLogout()}>{t("auth.logout")}</Link>
                </li>
              </ul>
            </div>
          ) : (
            <div className="sign_inup">
              <a href={`/auth?from=${location.pathname}`}>
                <FaUserAlt style={{ marginRight: "10px" }} />

                <span>
                  {t("header.sign_in")} / {t("header.register")}
                </span>
              </a>
            </div>
          )}
          <div className="my_cart">
            <Link to={navConfig.carts.url}>
              <BsBasket style={{ marginRight: "10px" }} />
              <span>{t("header.my_cart")}</span>
              <span className="cart_count">
                {others.lengthDram + others.lengthBottle + cartsOther.length}
              </span>
            </Link>
          </div>
          {user?.username && (
            <div className="wallet">
              {/* <Tippy
                placement="bottom"
                content={
                  <div>
                    <p>{wallet.balance} $</p>
                  </div>
                }
                className="wallet_tooltip"
              >
                <Link to="/my-account/wallet">
                  <FaWallet></FaWallet>
                  <span>{t("header.wallet")}</span>:
                  <span className="wallet_amount">SGD ${wallet.balance}</span>
                </Link>
              </Tippy> */}
              <Link to="/">
                {" "}
                {/*/my-account/wallet*/}
                <FaWallet></FaWallet> {/* <span>{t("header.wallet")}</span>: */}
                <span className="wallet_amount">
                  {formatCurrencySGD(wallet.balance)}
                </span>
              </Link>
            </div>
          )}
          <div className="social_icon">
            <span>
              <a
                href="https://www.facebook.com/AlcoVaultwhisky"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <BsFacebook size={25} />
              </a>
            </span>
            <span>
              <a
                href="https://www.instagram.com/alcovault_whisky/"
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram"
              >
                <BsInstagram size={25} />
              </a>
            </span>
          </div>
          {/* {!isMobile && (
            <ReactFlagsSelect
              countries={["US", "CN"]}
              selected={lang}
              onSelect={(code) => setLang(code)}
              className="selectLanguale"
              showSelectedLabel={false}
              showOptionLabel={false}
              showSecondaryOptionLabel={false}
            />
          )} */}
        </div>
      </div>
      <div></div>
      <Link className="review-overall" to={PATHS.reviewAndRating.root}>
        <Tippy
          placement="bottom"
          content={
            <div className="tippy-rating">
              <div className="tippy-rating-item">
                <span>Service:</span>
                <StarRatings
                  rating={Number(infoReview?.service)}
                  starRatedColor="#f1c40f"
                  numberOfStars={5}
                  name="rating"
                  starDimension={"15px"}
                  starSpacing="0px"
                />
              </div>
              <div className="tippy-rating-item">
                <span>Price:</span>
                <StarRatings
                  rating={Number(infoReview?.price)}
                  starRatedColor="#f1c40f"
                  numberOfStars={5}
                  name="rating"
                  starDimension={"15px"}
                  starSpacing="0px"
                />
              </div>
              <div className="tippy-rating-item">
                <span>Delivery:</span>
                <StarRatings
                  rating={Number(infoReview?.delivery)}
                  starRatedColor="#f1c40f"
                  numberOfStars={5}
                  name="rating"
                  starDimension={"15px"}
                  starSpacing="0px"
                />
              </div>
              <div className="tippy-rating-item">
                <span>Product Quality:</span>
                <StarRatings
                  rating={Number(infoReview?.quantity)}
                  starRatedColor="#f1c40f"
                  numberOfStars={5}
                  name="rating"
                  starDimension={"15px"}
                  starSpacing="0px"
                />
              </div>
              <div className="tippy-rating-item">
                <span>Web Friendly: </span>
                <StarRatings
                  rating={Number(infoReview?.website)}
                  starRatedColor="#f1c40f"
                  numberOfStars={5}
                  name="rating"
                  starDimension={"15px"}
                  starSpacing="0px"
                />
              </div>
            </div>
          }
          className="review-tooltip"
        >
          <div className="review-overall-container">
            <StarRatings
              rating={Number(infoReview?.overall)}
              starRatedColor="#f1c40f"
              name="rating"
              numberOfStars={5}
              starDimension={isMobile ? "17px" : "20px"}
              starSpacing="2px"
            />
            <div className="review-count">
              <span>{infoReview?.overall}</span>
              <span> / 5</span>
              <span> with over</span>
              <span> {infoReview?.count} reviews</span>
            </div>
          </div>
        </Tippy>
      </Link>
      <DialogCalculator visible={visible} setVisible={setVisible} />
    </div>
  );
}
