import { loadStripe } from "@stripe/stripe-js";
import axiosClient from "api/axiosClient";
import config from "config/config";
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./index.scss";

export default function Membership() {
  const [paymentInfo, setPaymentInfo] = React.useState(null);
  const location = useLocation();
  const sessionId = new URLSearchParams(location.search).get("session_id");

  const fetchPaymentInfo = useCallback(async () => {
    if (sessionId) {
      const response = await axiosClient.get(
        `/auth/upgrade-membership/${sessionId}`
      );
      setPaymentInfo(response);
    }
  }, [sessionId]);

  useEffect(() => {
    fetchPaymentInfo();
  }, [fetchPaymentInfo]);

  return (
    <div id="membership">
      <h1 className="membership__title">Membership</h1>
      {paymentInfo?.status === "success" ? (
        <>
          <p>Payment status: {paymentInfo?.message}</p>
          <p>Username: {paymentInfo?.username}</p>
          <p>Email: {paymentInfo?.email}</p>
          {/* <p>Amount: {paymentInfo?.price}</p> */}
        </>
      ) : (
        <p>Payment status: {paymentInfo?.message || "Failed"}</p>
      )}
    </div>
  );
}
