import { createSlice } from "@reduxjs/toolkit";
import AttributeApi from "api/attribute.api";
import WhiskyCategoryApi from "api/whiskycategory.api";

const initialState = {
  isloading: false,
  error: null,
  categories: [],
  distilleries: [],
};

export const attributeSlice = createSlice({
  name: "attribute",
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.isloading = true;
    },
    hasErrors: (state, action) => {
      state.isloading = false;
      state.error = action.payload;
    },
    getCategoriesSuccess: (state, action) => {
      state.isloading = false;
      state.categories = action.payload;
    },
    getDistilleriesSuccess: (state, action) => {
      state.isloading = false;
      state.distilleries = action.payload;
    },
  },
});

export const {
  startLoading,
  hasErrors,
  getCategoriesSuccess,
  getDistilleriesSuccess,
} = attributeSlice.actions;
export default attributeSlice.reducer;

export const getCategories = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await WhiskyCategoryApi.getAll();
    dispatch(getCategoriesSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getDistilleries = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await AttributeApi.getDistilleries();
    dispatch(getDistilleriesSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};
