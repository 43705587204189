import config from "config/config";
import React, { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function ReCAPTCHAS({ setIsCaptcha }) {
  // useEffect(() => {
  //   setIsCaptcha(true);
  // }, []);

  const handleChange = (event) => {
    if (event) {
      setIsCaptcha(true);
    } else {
      setIsCaptcha(false);
    }
  };

  return (
    <div className="box captcha">
      <ReCAPTCHA sitekey={config.recaptcha.sitekey} onChange={handleChange} />
    </div>
  );
}

// export react.memo
