import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/axiosClient";
import SettingApi from "api/setting.api";

const initialState = {
  isloading: false,
  error: null,
  targetBuyContent: null,
  homeContent: null,
  bottleContent: null,
  dramContent: null,
  reviewRatingContent: null,
  previousBidContent: null,
  submitShareContent: null,
  requestValuationContent: null,
  shippingContent: null,
  howToBidContent: null,
  howToSellContent: null,
  independenBottlersContent: null,
  termsAndConditionsContent: null,
  fAQContent: null,
  newAndArticlesContent: null,
  shipping_delivery_calculator: null,
  cartContent: null,
  partnerShipContent: null,
  productDetailContent: null,
  aboutContent: null,
  caskContent: null,
  buyWhiskyCaskContent: null,
};

export const settingcontentSlice = createSlice({
  name: "settingcontent",
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.isloading = true;
    },
    hasErrors: (state, action) => {
      state.isloading = false;
      state.error = action.payload;
    },
    getTargetBuyContentSuccess: (state, action) => {
      state.isloading = false;
      state.targetBuyContent = action.payload;
    },
    getHomeContentSuccess: (state, action) => {
      state.isloading = false;
      state.homeContent = action.payload;
    },
    getBottleContentSuccess: (state, action) => {
      state.isloading = false;
      state.bottleContent = action.payload;
    },
    getDramContentSuccess: (state, action) => {
      state.isloading = false;
      state.dramContent = action.payload;
    },
    getReviewRatingContentSuccess: (state, action) => {
      state.isloading = false;
      state.reviewRatingContent = action.payload;
    },
    getPreviousBidContentSuccess: (state, action) => {
      state.isloading = false;
      state.previousBidContent = action.payload;
    },
    getSubmitShareContentSuccess: (state, action) => {
      state.isloading = false;
      state.submitShareContent = action.payload;
    },
    getRequestValuationContentSuccess: (state, action) => {
      state.isloading = false;
      state.requestValuationContent = action.payload;
    },
    getShippingContentSuccess: (state, action) => {
      state.isloading = false;
      state.shippingContent = action.payload;
    },
    getHowToBidContentSuccess: (state, action) => {
      state.isloading = false;
      state.howToBidContent = action.payload;
    },
    getHowToSellContentSuccess: (state, action) => {
      state.isloading = false;
      state.howToSellContent = action.payload;
    },
    getIndependenBottlersContentSuccess: (state, action) => {
      state.isloading = false;
      state.independenBottlersContent = action.payload;
    },
    getTermsAndConditionsContentSuccess: (state, action) => {
      state.isloading = false;
      state.termsAndConditionsContent = action.payload;
    },
    getfAQContentSuccess: (state, action) => {
      state.isloading = false;
      state.fAQContent = action.payload;
    },
    getNewAndArticlesContentSuccess: (state, action) => {
      state.isloading = false;
      state.newAndArticlesContent = action.payload;
    },
    getShippingDeliveryCalculatorSuccess: (state, action) => {
      state.isloading = false;
      state.shipping_delivery_calculator = action.payload;
    },
    getCartSuccess: (state, action) => {
      state.isloading = false;
      state.cartContent = action.payload;
    },
    getPartnerShipSuccess: (state, action) => {
      state.isloading = false;
      state.partnerShipContent = action.payload;
    },
    getProductDetailSuccess: (state, action) => {
      state.isloading = false;
      state.productDetailContent = action.payload;
    },
    getAboutContentSuccess: (state, action) => {
      state.isloading = false;
      state.aboutContent = action.payload;
    },
    getCaskContentSuccess: (state, action) => {
      state.isloading = false;
      state.caskContent = action.payload;
    },
    getBuyWhiskyCaskContentSuccess: (state, action) => {
      state.isloading = false;
      state.buyWhiskyCaskContent = action.payload;
    },
  },
});

export const {
  startLoading,
  hasErrors,
  getTargetBuyContentSuccess,
  getHomeContentSuccess,
  getBottleContentSuccess,
  getDramContentSuccess,
  getReviewRatingContentSuccess,
  getPreviousBidContentSuccess,
  getSubmitShareContentSuccess,
  getRequestValuationContentSuccess,
  getShippingContentSuccess,
  getHowToBidContentSuccess,
  getHowToSellContentSuccess,
  getIndependenBottlersContentSuccess,
  getTermsAndConditionsContentSuccess,
  getfAQContentSuccess,
  getNewAndArticlesContentSuccess,
  getShippingDeliveryCalculatorSuccess,
  getCartSuccess,
  getPartnerShipSuccess,
  getProductDetailSuccess,
  getAboutContentSuccess,
  getCaskContentSuccess,
  getBuyWhiskyCaskContentSuccess,
} = settingcontentSlice.actions;

export default settingcontentSlice.reducer;

export const getTargetBuyContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await SettingApi.getTargetBuyContent();
    dispatch(getTargetBuyContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getHomeContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await SettingApi.getHomeContent();
    dispatch(getHomeContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getBottleContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await SettingApi.getBottleContent();
    dispatch(getBottleContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getCaskContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axiosClient.get("/setting/content/Cask Shop");
    dispatch(getCaskContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getDramContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await SettingApi.getDramContent();
    dispatch(getDramContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getReviewRatingContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await SettingApi.getReviewRatingContent();
    dispatch(getReviewRatingContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getPreviousBidContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await SettingApi.getPreviousBidContent();
    dispatch(getPreviousBidContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getSubmitShareContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await SettingApi.getSubmitShareContent();
    dispatch(getSubmitShareContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};
export const getRequestValuationContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axiosClient.get(
      "/setting/content/Request Valuation"
    );
    dispatch(getRequestValuationContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};
export const getShippingContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await SettingApi.getShippingContent();
    dispatch(getShippingContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getHowToBidContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await SettingApi.getHowToBuyContent();
    dispatch(getHowToBidContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getHowToSellContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await SettingApi.getHowToSellContent();
    dispatch(getHowToSellContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getIndependenBottlersContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await SettingApi.getIndependenBottlersContent();
    dispatch(getIndependenBottlersContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};
export const getTermsAndConditionsContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await SettingApi.getTermsAndConditionsContent();
    dispatch(getTermsAndConditionsContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};
export const getfAQContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await SettingApi.getfAQContent();
    dispatch(getfAQContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getNewAndArticlesContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await SettingApi.getNewAndArticlesContent();
    dispatch(getNewAndArticlesContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getShippingDeliveryCalculator = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axiosClient.get(
      "/setting/content/Shipping Delivery Calculator"
    );
    dispatch(getShippingDeliveryCalculatorSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getCartContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axiosClient.get("/setting/content/Cart");
    dispatch(getCartSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};
export const getPartnerShipContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axiosClient.get("/setting/content/Partner Ships");
    dispatch(getPartnerShipSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getProductDetailContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axiosClient.get("/setting/content/Product Detail");
    dispatch(getProductDetailSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getAboutContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axiosClient.get("/setting/content/About Us");
    dispatch(getAboutContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};

export const getBuyWhiskyCaskContent = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axiosClient.get("/setting/content/Buy Whisky Cask");
    dispatch(getBuyWhiskyCaskContentSuccess(response));
  } catch (error) {
    dispatch(hasErrors(error.message));
  }
};
