import { useMemo } from "react";
import { useTranslation } from "react-i18next";

function useLang() {
  const { i18n } = useTranslation();

  const isCN = useMemo(() => i18n.language === "CN", [i18n.language]);

  return { isCN };
}

export default useLang;
