import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fil_auction: {},
};

const slice = createSlice({
  name: "filter_sort",
  initialState,
  reducers: {
    setFilterAuction: (state, action) => {
      state.fil_auction = action.payload;
    },
  },
});

export const { setFilterAuction } = slice.actions;
export default slice.reducer;
