import { useCallback, useEffect, useState } from "react";

const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [scrollY, setScrollY] = useState(window.scrollY);

  const handleWindowResize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [handleWindowResize]);

  const handleWindowScroll = useCallback(() => {
    setScrollY(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => window.removeEventListener("scroll", handleWindowScroll);
  }, [handleWindowScroll]);

  return { width, scrollY };
};

export default useViewport;
