import axiosClient from "./axiosClient";

const RatingApi = {
  getRatings: () => {
    return axiosClient.get("/rating/status/active");
  },
  getRatingCount: () => {
    return axiosClient.get("/rating/count");
  },
  getRatingCountByProduct: (id) => {
    return axiosClient.get(`/rating/count/${id}`);
  },
  getRatingByProduct: (id) => {
    return axiosClient.get(`/rating/product/${id}`);
  },
  submitRating: (data) => {
    return axiosClient.post("/rating/create", data);
  },
  getRatingProductAndUser: (id_prod, email) => {
    return axiosClient.get(`/rating/${id_prod}/${email}`);
  },
  getRatingUser: (email) => {
    return axiosClient.get(`/rating/ratinguser/${email}`);
  },
};

export default RatingApi;
