import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/axiosClient";

const initialState = {
  places: [],
  place: {},
  loading: false,
  error: null,
};

const placeBidSlice = createSlice({
  name: "placeBid",
  initialState,
  reducers: {
    getPlacesStart(state) {
      state.loading = true;
    },
    getPlacesSuccess(state, action) {
      state.places = action.payload;
      state.loading = false;
    },
    getPlacesFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getStepByPlaces(state, action) {
      const price = action.payload;
      if (!price) return 0;
      const { step } = state.places.find(
        (place) => place.min <= price && place.max > price
      );
      return step;
    },
  },
});

export const {
  getPlacesStart,
  getPlacesSuccess,
  getPlacesFailure,
  getStepByPlaces,
} = placeBidSlice.actions;

export default placeBidSlice.reducer;

export const fetchPlaces = () => async (dispatch) => {
  try {
    dispatch(getPlacesStart());
    const res = await axiosClient.get(`/place-bid/find-all`);
    dispatch(getPlacesSuccess(res || []));
  } catch (error) {}
};
