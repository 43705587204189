import React from "react";

export default function VietNamLayout() {
  return (
    <div>
      The Vietnamese website is currently under development, please select the
      country of Singapore to purchase
    </div>
  );
}
