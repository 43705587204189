import { Dialog } from "primereact/dialog";
import React, { useEffect, useLayoutEffect } from "react";
import DialogLogin from "./DialogLogin";
import DialogRegister from "./DialogRegister";
import "./index.scss";
import { PATHS } from "routes/paths";
import useViewport from "hooks/ViewPort";
import { useNavigate } from "react-router-dom";

export default function LoginDialog({
  visible,
  setVisible,
  title,
  visiableArticle,
  auction,
  title2,
  tab,
  removeRegister,
}) {
  useLayoutEffect(() => {
    // block scroll when dialog is open
    if (visible) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [visible]);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState("register");

  useEffect(() => {
    if (!tab) {
      return;
    } else {
      setActiveTab(tab);
    }
  }, [tab]);

  const onCloseDialog = () => {
    if (title === "Login to submit your article") {
      setVisible(false);
      const anchor = document.createElement("a");
      anchor.href = PATHS.newsAndArticles.root;
      anchor.click();
    } else {
      if (auction) {
        if (window.history.length > 1) {
          if (document.referrer.includes(window.location.origin)) {
            window.history.back();
          } else {
            window.location.href = "/";
          }
        } else {
          navigate(PATHS.reviewAndRating.root);
        }
      }
    }
    setVisible(false);
  };

  const viewPort = useViewport();
  const isMobile = viewPort.width <= 1024;
  return (
    <>
      {isMobile ? (
        <>
          <Dialog
            visible={visible}
            onHide={onCloseDialog}
            style={{ width: "100vw", overflow: "auto" }}
            breakpoints={{
              "960px": "80vw",
              "800px": "98vw",
              overflow: "auto",
            }}
            position={"left"}
            blockScroll={true}
          >
            <h1
              style={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              {title2}
            </h1>
            <div className="auth_dialog">
              <div className="tab">
                <div
                  className={`tab-item ${
                    activeTab === "login" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("login")}
                >
                  Login
                </div>
                {!removeRegister && (
                  <div
                    className={`tab-item ${
                      activeTab === "register" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("register")}
                  >
                    Register
                  </div>
                )}
              </div>
              <div className="tab-content">
                {activeTab === "login" && (
                  <DialogLogin setVisible={setVisible} title={title} />
                )}
                {activeTab === "register" && (
                  <DialogRegister setVisible={setVisible} />
                )}
              </div>
            </div>
          </Dialog>
        </>
      ) : (
        <>
          <Dialog
            visible={visible}
            onHide={onCloseDialog}
            maximizable={false}
            style={{ width: "700px", overflow: "auto" }}
            headerStyle={{ height: "0px", padding: "10px 20px" }}
            breakpoints={{
              "960px": "75vw",
              "641px": "90vw",
              overflow: "auto",
            }}
            draggable={false}
            blockScroll={true}
          >
            <div className="auth_dialog">
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                {title2}
              </h1>
              <div className="tab">
                <div
                  className={`tab-item ${
                    activeTab === "login" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("login")}
                >
                  Login
                </div>
                {!removeRegister && (
                  <div
                    className={`tab-item ${
                      activeTab === "register" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("register")}
                  >
                    Register
                  </div>
                )}
              </div>
              <div className="tab-content">
                {activeTab === "login" && (
                  <DialogLogin
                    setVisible={setVisible}
                    title={title}
                    visiableArticle={visiableArticle}
                  />
                )}
                {activeTab === "register" && (
                  <DialogRegister
                    setVisible={setVisible}
                    visiableArticle={visiableArticle}
                  />
                )}
              </div>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
}
