import { PATHS, PATHS_DISTILLERY } from "routes/paths";

const navFooterOneConfig = {
  items: [
    {
      name: "buy_whisky_cask",
      url: PATHS.buy_whisky_cask,
    },
    {
      // name: "Auction Schedule",
      name: "auction_schedule",
      url: PATHS.auctionSchedule,
    },
    {
      // name: "Distilleries",
      name: "distilleries",
      url: PATHS_DISTILLERY.root,
    },
    {
      // name: "Glossary",
      name: "glossary",
      url: PATHS.glossary,
    },
    {
      // name: "Independent Bottlers",
      name: "independent_bottlers",
      url: PATHS.independenBottlers,
    },
    {
      // name: "Partnerships",
      name: "partnerships",
      url: PATHS.partnerShips,
    },
    {
      // name: "Whisky News",
      name: "whisky_news",
      url: PATHS.newsAndArticles.root,
    },
  ],
};

export default navFooterOneConfig;
