import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axiosClient from "api/axiosClient";
import { useDispatch } from "react-redux";
import { clearCart } from "redux/modules/cart";
import { PATHS } from "routes/paths";
import { GetOrdersByUser } from "redux/modules/order";
import { getCookie } from "config/cookies";
import { FaStripe } from "react-icons/fa";

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const user = getCookie("user") ? JSON.parse(getCookie("user")) : null;
  const [time, setTime] = useState(5);

  const domain = window.location.origin;

  useEffect(() => {
    if (time === 0) {
      window.location.href = `/`;
    }
  }, [time]);

  const timer = () => {
    const timer = setInterval(() => {
      setTime((time) => time - 1);
    }, 1000);
    return () => clearInterval(timer);
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          axiosClient.post("/payment/checkout-session-success", {
            clientSecret,
          });
          dispatch(clearCart());
          dispatch(GetOrdersByUser(user?._id));
          timer();
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [dispatch, stripe, user?._id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "http://localhost:3000/singapore/checkout",
        return_url: `${domain}${PATHS.checkout.root}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {!(message === "Payment succeeded!") && (
        <>
          <div className="logo">
            <FaStripe className="stripe-logo" />
          </div>
          {/* <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.target.value)}
        defaultValue={email}
        value={email}
      /> */}
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
          />
          <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay now"
              )}
            </span>
          </button>
        </>
      )}
      {/* Show any error or success messages */}
      {message && (
        <div
          style={{
            width: "500px",
            margin: "20px auto",
            padding: "20px",
            textAlign: "center",
            color: "#5d5365",
            background: "#fff",
          }}
          id="payment-message"
        >
          {message}
          {message === "Payment succeeded!" && (
            <div>Redirecting to home page in {time} seconds...</div>
          )}
        </div>
      )}
    </form>
  );
}

export default React.memo(CheckoutForm);
