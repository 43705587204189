import { getCookie } from "config/cookies";
import useViewport from "hooks/ViewPort";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { loginAsync, selectAuth } from "redux/modules/authenticate";
import { WalletAsync } from "redux/modules/wallet";
import countries from "assets/json/country.json";
import Select from "react-select";

export default function DeliveryForm({
  user,
  setDelivery,
  setUser,
  delivery,
  checkNext,
  setCheckNext,
  selectShipping,
  upgrade,
}) {
  const userSelect = useSelector(selectAuth);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [country, setCountry] = React.useState("Singapore");

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (getCookie("user") && getCookie("user") !== "undefined") {
      if (userSelect.data.username) {
        setUser(userSelect.data);
      } else {
        setUser(JSON.parse(getCookie("user")));
      }
      dispatch(WalletAsync(JSON.parse(getCookie("user"))._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userSelect.data]);

  const onLogin = (e) => {
    e.preventDefault();

    dispatch(loginAsync({ email: email, password }))
      .then((res) => {
        if (res.payload.status === "success") {
          toast.success(t("auth.login_success"), {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.error(t("auth.invalid_email_or_password"), {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(t("auth.error_occurred"), {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const onChange = (e, name) => {
    let value = e.target.value;
    let data = {
      ...delivery,
      [name]: value,
    };

    setDelivery(data);
  };
  const viewPort = useViewport();
  const isMobile = viewPort.width <= 900;
  return (
    <div className="container">
      {user && (
        <div className="delivery_form">
          <h1>Welcome to Alcovault Whisky Auction</h1>
          <p>
            {!["free-storage", "self"].includes(selectShipping) && !upgrade
              ? "Please enter your delivery details below."
              : "Please enter your information"}
          </p>
          <form>
            <div className="form-group">
              <label>First Name</label>
              <input
                className="form-control"
                type="text"
                value={delivery?.firstname}
                onChange={(e) => onChange(e, "firstname")}
              />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input
                className="form-control"
                type="text"
                value={delivery?.lastname}
                onChange={(e) => onChange(e, "lastname")}
              />
            </div>
            <div className="form-group">
              <label>Company Name</label>
              <input
                className="form-control"
                type="text"
                value={delivery?.companyname}
                onChange={(e) => onChange(e, "companyname")}
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                className="form-control"
                type="text"
                value={delivery?.email}
                onChange={(e) => onChange(e, "email")}
              />
            </div>
            <div className="form-group">
              <label>Phone</label>
              <input
                className="form-control"
                type="text"
                value={delivery?.phone}
                onChange={(e) => onChange(e, "phone")}
              />
            </div>
            {!["free-storage", "self"].includes(selectShipping) && !upgrade ? (
              <>
                <div className="form-group">
                  <label>Delivery Address</label>
                  <input
                    className="form-control"
                    type="text"
                    value={delivery?.address}
                    onChange={(e) => onChange(e, "address")}
                  />
                </div>
                <div className="form-group">
                  <label>City</label>
                  <input
                    className="form-control"
                    type="text"
                    value={delivery?.city}
                    onChange={(e) => onChange(e, "city")}
                  />
                </div>
                <div className="form-group">
                  <label>State</label>
                  <input
                    className="form-control"
                    type="text"
                    value={delivery?.state}
                    onChange={(e) => onChange(e, "state")}
                  />
                </div>
                <div className="form-group">
                  <label>Postal Code</label>
                  <input
                    className="form-control"
                    type="text"
                    value={delivery?.postal_code}
                    onChange={(e) => onChange(e, "postal_code")}
                  />
                </div>
                <div className="form-group">
                  <label>Country</label>
                  <input
                    className="form-control"
                    type="text"
                    value={delivery?.country}
                    disabled
                  />
                </div>
              </>
            ) : null}

            {upgrade && (
              <div className="form-group">
                <label>Country</label>
                <Select
                  className="select-country"
                  classNamePrefix="select"
                  isSearchable={true}
                  options={countries}
                  getOptionValue={(option) => option.label}
                  getOptionLabel={(option) => option.label}
                  value={{ label: delivery?.country }}
                  onChange={(e) => {
                    setCountry(e.label);
                    setDelivery({ ...delivery, country: e.label });
                  }}
                />
              </div>
            )}
          </form>
        </div>
      )}
      {!user && (
        <div className="guest_checkout">
          {isMobile ? (
            <div style={{ display: "-ms-grid" }}>
              <div className="checkout_info">
                <h1>GUEST CHECKOUT</h1>
                <p>Please enter your delivery details below.</p>
                <form>
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.firstname}
                      onChange={(e) => onChange(e, "firstname")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.lastname}
                      onChange={(e) => onChange(e, "lastname")}
                    />
                  </div>

                  <div className="form-group">
                    <label>Company Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.company_name}
                      onChange={(e) => onChange(e, "company_name")}
                    />
                  </div>

                  <div className="form-group">
                    <label>Email</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.email}
                      onChange={(e) => onChange(e, "email")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.phone}
                      onChange={(e) => onChange(e, "phone")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Delivery Address</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.address}
                      onChange={(e) => onChange(e, "address")}
                    />
                  </div>
                  <div className="form-group">
                    <label>City</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.city}
                      onChange={(e) => onChange(e, "city")}
                    />
                  </div>
                  <div className="form-group">
                    <label>State</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.state}
                      onChange={(e) => onChange(e, "state")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Postal Code</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.postal_code}
                      onChange={(e) => onChange(e, "postal_code")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password (to create an account)</label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Optional"
                      value={delivery?.password}
                      onChange={(e) => onChange(e, "password")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.country}
                      disabled
                    />
                  </div>
                </form>
              </div>
              <div className="exitsing_custommer">
                <h1>EXISTING CUSTOMER</h1>
                <p>
                  If you have an account with us, please log in. If you are a
                  new customer, please proceed to the Billing & Shipping
                  section.
                </p>
                <form onSubmit={(e) => onLogin(e)}>
                  <div className="form-group">
                    <label>Email / Username</label>
                    <input
                      className="form-control"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      className="form-control"
                      type="text"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                </form>
              </div>
              {/* <div className="checkout_next">
                <button
                  onClick={() => {
                    setCheckNext(!checkNext);
                  }}
                >
                  Next
                </button>
              </div> */}
            </div>
          ) : (
            <>
              <div className="checkout_info">
                <h1>GUEST CHECKOUT</h1>
                <p>Please enter your delivery details below.</p>
                <form>
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.firstname}
                      onChange={(e) => onChange(e, "firstname")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.lastname}
                      onChange={(e) => onChange(e, "lastname")}
                    />
                  </div>

                  <div className="form-group">
                    <label>Company Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.company_name}
                      onChange={(e) => onChange(e, "company_name")}
                    />
                  </div>

                  <div className="form-group">
                    <label>Email</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.email}
                      onChange={(e) => onChange(e, "email")}
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      className="form-control"
                      type="text"
                      value={delivery?.phone}
                      onChange={(e) => onChange(e, "phone")}
                    />
                  </div>
                  {!["free-storage", "self"].includes(selectShipping) && (
                    <>
                      <div className="form-group">
                        <label>Delivery Address</label>
                        <input
                          className="form-control"
                          type="text"
                          value={delivery?.address}
                          onChange={(e) => onChange(e, "address")}
                        />
                      </div>
                      <div className="form-group">
                        <label>City</label>
                        <input
                          className="form-control"
                          type="text"
                          value={delivery?.city}
                          onChange={(e) => onChange(e, "city")}
                        />
                      </div>
                      <div className="form-group">
                        <label>State</label>
                        <input
                          className="form-control"
                          type="text"
                          value={delivery?.state}
                          onChange={(e) => onChange(e, "state")}
                        />
                      </div>
                      <div className="form-group">
                        <label>Postal Code</label>
                        <input
                          className="form-control"
                          type="text"
                          value={delivery?.postal_code}
                          onChange={(e) => onChange(e, "postal_code")}
                        />
                      </div>
                      <div className="form-group">
                        <label>Country</label>
                        <input
                          className="form-control"
                          type="text"
                          value={delivery?.country}
                          disabled
                        />
                      </div>
                    </>
                  )}

                  <div className="form-group">
                    <label>Password (to login your account next time)</label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Optional"
                      value={delivery?.password}
                      onChange={(e) => onChange(e, "password")}
                    />
                  </div>
                </form>
              </div>
              <div className="exitsing_custommer">
                <h1>EXISTING CUSTOMER</h1>
                <p>
                  If you have an account with us, please log in. If you are a
                  new customer, please proceed to the Billing & Shipping
                  section.
                </p>
                <form onSubmit={(e) => onLogin(e)}>
                  <div className="form-group">
                    <label>Email / Username</label>
                    <input
                      className="form-control"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      className="form-control"
                      type="text"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                </form>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
