import axiosClient from "./axiosClient";

const SettingApi = {
  getTargetBuyContent: () => {
    return axiosClient.get("/setting/content/Target Buy");
  },
  getHomeContent: () => {
    return axiosClient.get("/setting/content/Home");
  },
  getBottleContent: () => {
    return axiosClient.get("/setting/content/Bottle Shop");
  },
  getDramContent: () => {
    return axiosClient.get("/setting/content/Dram Shop");
  },
  getReviewRatingContent: () => {
    return axiosClient.get("/setting/content/Review Rating");
  },
  getPreviousBidContent: () => {
    return axiosClient.get("/setting/content/Previous Bid");
  },
  getSubmitShareContent: () => {
    return axiosClient.get("/setting/content/Submit Share");
  },
  getShippingContent: () => {
    return axiosClient.get("/setting/content/Shipping");
  },
  getHowToBuyContent: () => {
    return axiosClient.get("/setting/content/How To Buy");
  },
  getHowToSellContent: () => {
    return axiosClient.get("/setting/content/How To Sell");
  },
  getIndependenBottlersContent: () => {
    return axiosClient.get("/setting/content/Independent Bottlers");
  },
  getTermsAndConditionsContent: () => {
    return axiosClient.get("/setting/content/Terms & Conditions");
  },
  getfAQContent: () => {
    return axiosClient.get("/setting/content/FAQ");
  },
  getNewAndArticlesContent: () => {
    return axiosClient.get("/setting/content/New Articles");
  },
};

export default SettingApi;
