import axiosClient from "api/axiosClient";
import { Dialog } from "primereact/dialog";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

function Notification() {
  const [data, setData] = React.useState([]);

  const { user } = useSelector((state) => state.auth);

  const fetchNotification = useCallback(async () => {
    try {
      if (user?._id) {
        const res = await axiosClient.get(`/notification`);
        if (res.length) {
          setData(res || []);
        }
      }
    } catch (error) {}
  }, [user?._id]);

  React.useEffect(() => {
    fetchNotification();
  }, [fetchNotification]);

  const handleReadNotification = async (id) => {
    try {
      await axiosClient.put(`/notification/${id}`);
      setData((prev) => prev.filter((item) => item._id !== id));
    } catch (error) {}
  };

  const notification = data[0];
  return (
    <Dialog
      visible={notification ? true : false}
      onHide={() => handleReadNotification(notification?._id)}
      style={{
        width: "95%",
        maxWidth: "500px",
        margin: "0 auto",
        border: "5px solid #5D5365",
      }}
      headerStyle={{
        height: "0px",
      }}
    >
      <div style={{ paddingTop: "0px" }}>
        <h2
          style={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "20px",
            fontFamily: "LatoBold",
          }}
        >
          {notification?.title}
        </h2>
        <div
          style={{
            textAlign: "center",
            marginBottom: "20px",
          }}
          dangerouslySetInnerHTML={{
            __html: notification?.content,
          }}
        ></div>

        {/* <button
          onClick={() => handleReadNotification(notification?._id)}
          style={{
            backgroundColor: "#5D5365",
            color: "white",
            border: "none",
            padding: "10px 20px",
            borderRadius: "5px",
            cursor: "pointer",
            display: "block",
            margin: "0 auto",
          }}
        >
          Close
        </button> */}
      </div>
    </Dialog>
  );
}

export default Notification;
