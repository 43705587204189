import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { registerAsync } from "redux/modules/authenticate";
import { useTranslation } from "react-i18next";
import countries from "assets/json/country.json";
import ReCAPTCHAS from "components/ReCAPTCHAS";
import OtherLogin from "components/Auth/OtherLogin";

export default function DialogRegister({ setVisible, visiableArticle }) {
  const [country, setCountry] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  // const [firstName, setFirstName] = React.useState("");
  // const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  // const [handPhone, setHandPhone] = React.useState("");
  // const [favorite, setFavorite] = React.useState("");
  const [isCaptcha, setIsCaptcha] = React.useState(false);

  const handleChangeCountry = (value) => {
    setCountry(value);
    // setHandPhone(value.dial_code);
  };

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (getCookie("user") && getCookie("user") !== "undefined") {
  //     getCookie("user") && navigate("/");
  //   }
  // }, [navigate]);

  const { t } = useTranslation();

  const handleRegister = (e) => {
    e.preventDefault();
    if (!isCaptcha) {
      toast.info("Please check your reCAPTCHA settings.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    const validate = validateRegister({
      username,
      password,
      confirmPassword,
      // firstName,
      // lastName,
      email,
      // handPhone,
      country,
    });
    if (validate.message) {
      toast.error(validate.message);
      return;
    }
    toast.info(t("auth.registering"), {
      position: toast.POSITION.TOP_CENTER,
    });
    dispatch(
      registerAsync({
        // first_name: firstName,
        // last_name: lastName,
        username,
        email,
        password,
        // phone_mb: handPhone,
        country_mb: country.label,
        country_code_mb: country.code,
        // favorite,
      })
    )
      .then((res) => {
        if (res.payload.status === "success") {
          toast.success(t("auth.register_success"), {
            position: toast.POSITION.TOP_CENTER,
          });
          setVisible(false);
          if (visiableArticle === true) {
            window.location.reload();
          }
        } else {
          if (res.payload.message === "email_or_user_already_exists") {
            toast.error("Email or Username already exists", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.error(t("auth.register_failed"), {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        }
      })
      .catch((err) => {
        toast.error(t("auth.register_failed"), {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  return (
    <div
      className="login"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleRegister(e);
        }
      }}
    >
      <h3 className="login_title">{t("auth.sign_up")}</h3>
      {/* <h1 className="login_welcome">{t("auth.welcome_whisky")}</h1> */}
      <form onSubmit={(e) => handleRegister(e)}>
        <div className="box">
          <div className="box__input">
            <label htmlFor="username">{t("auth.username")}</label>
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
            />
          </div>

          {/* <div className="box__input">
            <label htmlFor="password">{t("auth.first_name")}</label>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
            />
          </div>
          <div className="box__input">
            <label htmlFor="password">{t("auth.last_name")}</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div> */}
          <div className="box__input">
            <label htmlFor="password">Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
          </div>
          <div className="box__input">
            <label htmlFor="password">{t("auth.country")}</label>
            <Select
              options={countries}
              value={country}
              onChange={handleChangeCountry}
              className={"box__input__select"}
              styles={{
                control: (base) => ({
                  ...base,
                  height: 40,
                  minHeight: 40,
                  alignContent: "center",
                  border: "1px solid #000",
                  borderRadius: 10,
                  color: "#000",
                }),
              }}
            />
          </div>
          {/* <div className="box__input">
            <label htmlFor="password">{t("auth.phone_mb")}</label>
            <input
              value={handPhone}
              onChange={(e) => {
                setHandPhone(e.target.value);
              }}
              type="text"
            />
          </div>

          <div className="box__input">
            <label htmlFor="favorite_whisky">
              What is your favorite whisky
            </label>
            <input
              value={favorite}
              onChange={(e) => setFavorite(e.target.value)}
              type="text"
            />
          </div> */}
          <div className="box__input">
            <label htmlFor="password">{t("auth.password")}</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </div>
          <div className="box__input">
            <label htmlFor="password">{t("auth.confirm_password")}</label>
            <input
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
            />
          </div>
        </div>
        <div className="box captcha">
          <ReCAPTCHAS setIsCaptcha={setIsCaptcha} />
        </div>
        <div className="login_button">
          <button disabled={!isCaptcha} type="submit">
            {t("auth.sign_up")}
          </button>
        </div>
      </form>
      <div className="login__forgot">
        <Link to="#">{t("auth.terms_conditions")}</Link>
      </div>
      <OtherLogin />
    </div>
  );
}

const validateRegister = (values) => {
  if (!values.username) {
    return { message: "Username is required" };
  }

  if (!values.password) {
    return { message: "Password is required" };
  }

  if (!values.confirmPassword) {
    return { message: "Confirm password is required" };
  }

  if (values.password !== values.confirmPassword) {
    return { message: "Passwords do not match" };
  }

  // if (!values.firstName) {
  //   return { message: "First name is required" };
  // }

  // if (!values.lastName) {
  //   return { message: "Last name is required" };
  // }

  if (!values.email) {
    return { message: "Email is required" };
  }

  // if (!values.handPhone) {
  //   return { message: "Phone number is required" };
  // }

  // //kiểm tra xem có dấu + ở đầu hay k
  // if (values.handPhone[0] !== "+") {
  //   return { message: "Phone number must start with +" };
  // }

  if (!values.country) {
    return { message: "Country is required" };
  }

  return {};
};
