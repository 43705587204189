import axiosClient from "./axiosClient";

const CheckoutApi = {
  createPaymentIntent: (data) => {
    return axiosClient.post("/payment/create-payment-intent", data);
  },
  paymentTranfer: (data) => {
    return axiosClient.post("/payment/payment-transfer", data);
  },
  paymentCard: (data) => {
    return axiosClient.post("/payment/payment-card", data);
  },
  upgrade: (data) => {
    return axiosClient.post("/payment/upgrade", data);
  },
};

export default CheckoutApi;
